import React, { useContext, ReactElement } from "react";
import { Button } from "react-bootstrap";
import { DashboardDataContext, NewDashboardType } from "./new-dashboard-context";
import { SnelInzichtResultaat } from "./snel-inzicht/snel-inzicht";
import { HypotheekAcceptatieResultaat } from "./hypotheek-acceptatie/hypotheek-acceptatie";
import { ArbeidsongeschiktheidResultaat } from "./arbeidsongeschiktheid/arbeidsongeschiktheid";
import { OverlijdenResultaat } from "./overlijden/overlijden";
import { WerkloosheidResultaat } from "./werkloosheid/werkloosheid";
import { PensioenResultaat } from "./pensioen/pensioen";
import "./new-dashboard.scss";
export const NewDashboardComponent = ({
  situatie,
  onClickOnderwerp
}: {
  situatie: string;
  onClickOnderwerp?: (type: NewDashboardType) => void;
}): ReactElement => {
  const { open, type, setOpen, setType } = useContext(DashboardDataContext);

  const handleClickOnderwerp = (newType: NewDashboardType): void => {
    if (onClickOnderwerp) {
      onClickOnderwerp(newType);
      return;
    }

    if (open && type === newType) {
      setOpen(false);
      return;
    }

    if (open && type !== NewDashboardType.Geen) {
      setType(newType);
      return;
    }

    setType(newType);
    setOpen(!open);
  };

  return (
    <div className="new-dashboard">
      <div className="new-dashboard__content">
        <div className="new-dashboard__title">
          <h1>Dashboard</h1>
          <Button name="Berekenen" size="lg" className="new-dashboard__button">
            Berekenen
          </Button>
        </div>
        <div className="new-dashboard__entry">
          <SnelInzichtResultaat
            onClick={() => handleClickOnderwerp(NewDashboardType.SnelInzicht)}
            situatie={situatie}
          />
        </div>
        {situatie === "voorstel" && (
          <div className="new-dashboard__entry">
            <HypotheekAcceptatieResultaat
              onClick={() => handleClickOnderwerp(NewDashboardType.HypotheekAcceptatie)}
              situatie={situatie}
            />
          </div>
        )}
        <div className="new-dashboard__entry">
          <OverlijdenResultaat onClick={() => handleClickOnderwerp(NewDashboardType.Overlijden)} situatie={situatie} />
        </div>
        <div className="new-dashboard__entry">
          <ArbeidsongeschiktheidResultaat
            onClick={() => handleClickOnderwerp(NewDashboardType.Arbeidsongeschiktheid)}
            situatie={situatie}
          />
        </div>
        <div className="new-dashboard__entry">
          <WerkloosheidResultaat
            onClick={() => handleClickOnderwerp(NewDashboardType.Werkloosheid)}
            situatie={situatie}
          />
        </div>
        <div className="new-dashboard__entry">
          <PensioenResultaat onClick={() => handleClickOnderwerp(NewDashboardType.Pensioen)} situatie={situatie} />
        </div>
      </div>
    </div>
  );
};

export default NewDashboardComponent;
