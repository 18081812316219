import { Card, CardWrapper, DataGrid, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikContextType } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { Deelnemer } from "./deelnemer/deelnemer";
import { pensioenAsyncSideEffects } from "./infra/determine-pensioen-async-side-effects";
import { determinePensioenSideEffects } from "./infra/determine-pensioen-side-effects";
import {
  PensioenenProps,
  pensioenenSchema,
  PensioenenState,
  pensioenSchema,
  PensioenState
} from "./infra/pensioen-schema";
import { getPensioenColumns } from "./pensioen-columns";
import Pensioengrondslag from "./pensioengrondslag/pensioengrondslag";
import Pensioenregeling from "./pensioenregeling/pensioenregeling";
import Pensioentoezeggingen from "./pensioentoezeggingen/pensioentoezeggingen";
import Pensioenuitvoerder from "./pensioenuitvoerder/pensioenuitvoerder";
import Werkgever from "./werkgever/werkgever";
import Werknemersbijdragen from "./werknemersbijdragen/werknemersbijdragen";

const Pensioen = (formikContext: FormikContextType<PensioenenState> & WithSaveData<PensioenenState>): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  const {
    isSubmitting,
    values: {
      pensioenen,
      deelnemers: [aanvrager1, aanvrager2]
    }
  } = formikContext;
  const [aanvrager1UsedLoondienstIds, setAanvrager1UsedLoondienstIds] = useState<string[]>();
  const [aanvrager2UsedLoondienstIds, setAanvrager2UsedLoondienstIds] = useState<string[]>();

  useEffect(() => {
    const aanvrager1UsedLoondienstIds = pensioenen
      .filter(c => c.selectedDeelnemer === aanvrager1?.klantId && c.loondienstId !== null)
      .map(c => c.loondienstId);
    setAanvrager1UsedLoondienstIds(aanvrager1UsedLoondienstIds as string[]);
  }, [aanvrager1, pensioenen]);

  const aanvrager1LoondienstIdsAvailable = aanvrager1?.loondiensten.filter(
    c => c.loondienstId !== null && !aanvrager1UsedLoondienstIds?.includes(c.loondienstId)
  );

  useEffect(() => {
    const aanvrager2UsedLoondienstIds = pensioenen
      .filter(c => c.selectedDeelnemer === aanvrager2?.klantId && c.loondienstId !== null)
      .map(c => c.loondienstId);
    setAanvrager2UsedLoondienstIds(aanvrager2UsedLoondienstIds as string[]);
  }, [aanvrager2, pensioenen]);

  const aanvrager2LoondienstIdsAvailable = aanvrager2?.loondiensten.filter(
    c => c.loondienstId !== null && !aanvrager2UsedLoondienstIds?.includes(c.loondienstId)
  );

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}

        <ISWSideEffects
          sync={determinePensioenSideEffects({ selected })}
          async={pensioenAsyncSideEffects({ selected })}
        />

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Pensioen</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={formikContext} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <div className="d-flex flex-wrap flex-row flex-grow-1">
          <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
            <Card className="w-xl-100 w-lg-100 w-md-50 w-50 mb-0">
              <DataGrid
                masterDetail
                columns={getPensioenColumns([aanvrager1, aanvrager2])}
                name="pensioenen"
                rowSelected={selectedState}
                validationSchema={pensioenenSchema}
                rowCaption="Pensioen"
                getNewRowValues={(): PensioenState => {
                  const klantId =
                    aanvrager1LoondienstIdsAvailable && aanvrager1LoondienstIdsAvailable.length > 0
                      ? aanvrager1?.klantId
                      : aanvrager2LoondienstIdsAvailable && aanvrager2LoondienstIdsAvailable.length > 0
                      ? aanvrager2?.klantId
                      : aanvrager1.klantId;

                  const defaultPensioen = { ...pensioenSchema.default() };

                  return {
                    ...defaultPensioen,
                    selectedDeelnemer: klantId,
                    loondienstId:
                      aanvrager1LoondienstIdsAvailable && aanvrager1LoondienstIdsAvailable.length > 0
                        ? aanvrager1LoondienstIdsAvailable[0].loondienstId
                        : aanvrager2LoondienstIdsAvailable && aanvrager2LoondienstIdsAvailable.length > 0
                        ? aanvrager2LoondienstIdsAvailable[0].loondienstId
                        : null,
                    pensioengrondslag: {
                      ...defaultPensioen.pensioengrondslag,
                      pensioengevendSalaris: {
                        ...defaultPensioen.pensioengrondslag.pensioengevendSalaris,
                        berekendBedrag: (aanvrager1LoondienstIdsAvailable && aanvrager1LoondienstIdsAvailable.length > 0
                          ? aanvrager1
                          : aanvrager2
                        )?.loondiensten
                          .map(e => e.totaalBrutoInkomenBedrag ?? 0)
                          .reduce((sum, curr) => (sum += curr), 0)
                      }
                    }
                  };
                }}
              />
            </Card>
          </CardWrapper>

          {pensioenen.length > 0 && (
            <>
              <CardWrapper flexType="flex-column" className="pl-3">
                <Card title="Werkgever">
                  <Werkgever selected={selected} />
                </Card>
                <Card title="Pensioenuitvoerder">
                  <Pensioenuitvoerder selected={selected} />
                </Card>
                <Card title="Deelnemer">
                  <Deelnemer selected={selected} />
                </Card>
                <Card title="Pensioenregeling">
                  <Pensioenregeling selected={selected} />
                </Card>
                {pensioenen[selected].werkgever.huidigeWerkgever && (
                  <Card title="Pensioengrondslag">
                    <Pensioengrondslag selected={selected} />
                  </Card>
                )}
                {pensioenen[selected].werkgever.huidigeWerkgever && (
                  <Card title="Werknemersbijdrage(n)">
                    <Werknemersbijdragen selected={selected} />
                  </Card>
                )}
              </CardWrapper>
              <CardWrapper flexType="flex-column" className="pr-3 p-left">
                <Card title="Pensioentoezeggingen">
                  <Pensioentoezeggingen selected={selected} />
                </Card>
              </CardWrapper>
            </>
          )}
        </div>

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

Pensioen.displayName = "Pensioen";

export default withAdviesboxFormik<PensioenenProps & WithSaveData<PensioenenState>, PensioenenState>({
  // Transform outer props into form values
  mapPropsToValues: (e: PensioenenProps): PensioenenState => e,
  validationSchema: pensioenenSchema
})(Pensioen);
