import { Card, CardWrapper, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useContext, useState } from "react";
import { AppDataContext } from "../navigation/appdata-context";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import {
  determineMaximaleHypotheekAsyncSideEffects,
  determineMaximaleHypotheekSideEffects
} from "./infra/determine-maximale-hypotheek-side-effects";
import {
  MaximaleHypotheekProps,
  maximaleHypotheekSchema,
  MaximaleHypotheekState
} from "./infra/maximale-hypotheek-schema";
import Inkomen from "./inkomen/inkomen";
import LeningdelenUitHetVerleden from "./leningdelen-uit-het-verleden/leningdelen-uit-het-verleden";
import { MaximaleHypotheekDataGrid } from "./maximale-hypotheek-data-grid/maximale-hypotheek-data-grid";
import Toetsrente from "./toetsrente/toetsrente";
import Uitgangspunten from "./uitgangspunten/uitgangspunten";

const MaximaleHypotheek = (
  props: FormikProps<MaximaleHypotheekState> & WithSaveData<MaximaleHypotheekState>
): ReactElement => {
  const { isSubmitting } = props;
  const [berekendState, setBerekendState] = useState(false);
  const { setSErunning } = useContext(AppDataContext);

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}
        <ISWSideEffects<MaximaleHypotheekState>
          sync={determineMaximaleHypotheekSideEffects}
          async={determineMaximaleHypotheekAsyncSideEffects}
          asyncStartStopCallback={setSErunning}
        />
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Maximale hypotheek</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper className="px-3">
          <Card title="Uitgangspunten">
            <Uitgangspunten />
          </Card>
          <Card title="Toetsrente">
            <Toetsrente />
          </Card>
        </CardWrapper>
        <CardWrapper className="px-3">
          <Card title="Leningdelen uit het verleden">
            <LeningdelenUitHetVerleden />
          </Card>
          <Card title="Inkomen">
            <Inkomen />
          </Card>
        </CardWrapper>

        <CardWrapper className="px-3">
          <Card
            title={"Maximale hypotheek"}
            className="w-xl-100 w-lg-100 w-md-50 w-50"
            fullscreenTonen
            disableFullScreenButton={!berekendState}
          >
            <MaximaleHypotheekDataGrid berekend={berekendState} setBerekend={setBerekendState} />
          </Card>
        </CardWrapper>
        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

MaximaleHypotheek.displayName = "MaximaleHypotheek";

export default withAdviesboxFormik<
  MaximaleHypotheekProps & WithSaveData<MaximaleHypotheekState>,
  MaximaleHypotheekState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: MaximaleHypotheekProps): MaximaleHypotheekState => e,
  validationSchema: maximaleHypotheekSchema
})(MaximaleHypotheek);
