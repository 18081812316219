/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import "adviesbox-shared/styles.css";
import "./index.css";
import App from "./App";
import { Charting } from "adviesbox-shared";

const { Chart, CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip, Filler } = Charting;
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip, Filler);

const cleanStaleUserData = (): void => {
  try {
    const now = Date.now() / 1000;

    Object.keys(sessionStorage)
      .filter((key): boolean => key.startsWith("oidc.user:"))
      .forEach((key): void => {
        const jsonData = sessionStorage.getItem(key);
        if (jsonData) {
          const userData = JSON.parse(jsonData);
          if (userData.expires_at < now) {
            /* eslint-disable-next-line no-console */
            console.warn(`Verlopen gebruikers data verwijderen: "${key}"`, userData);
            sessionStorage.removeItem(key);
          }
        }
      });
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.error(err);
  }
};

cleanStaleUserData();

ReactDOM.render(<App />, document.getElementById("root"));
