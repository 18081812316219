// istanbul ignore file
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { createContext, ReactElement, ReactNode, useState, useEffect } from "react";
import { useRequestInit } from "adviesbox-shared";

type FeatureProviderProps = {
  children: ReactNode;
  vestigingId: string;
};

export type Feature = { [key: string]: boolean } | null | undefined;

export type FeatureContextType = {
  features: Feature;
  setFeatures: React.Dispatch<React.SetStateAction<Feature>>;
};

export const FeatureContext = createContext<FeatureContextType>({
  features: {},
  setFeatures: () => {},
});

export const FeatureProvider = ({ vestigingId, children }: FeatureProviderProps): ReactElement => {
  const [features, setFeatures] = useState<Feature>(null);
  const { user, settings } = useRequestInit();
  const url = `${settings.foundationOrigin}/Features/`;

  useEffect(() => {
    if (!vestigingId || !user || !settings) return;

    const fetchFeatures = async (): Promise<void> => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `${user?.token_type} ${user?.access_token}`,
            "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
            "Content-Type": "application/json;charset=UTF-8",
            vestigingId
          }
        });

        if (!response.ok) {
          console.error(`Failed to fetch features: ${response.statusText}`);
          return;
        }

        const data = await response.json();
        setFeatures(data.featureStates);
      } catch (error) {
        console.error("Error fetching features", error);
      }
    };

    fetchFeatures();
  }, [vestigingId, user, settings, url]);

  return (
    <FeatureContext.Provider
      value={{
        features,
        setFeatures,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
};
