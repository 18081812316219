import { Card, CardWrapper, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikContextType } from "formik";
import React, { ReactElement, useContext } from "react";
import { AppDataContext } from "../navigation/appdata-context";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import AanvullendeInformatie from "./aanvullende-informatie/aanvullende-informatie";
import { default as AlgemeenTaxatie } from "./algemeen-taxatie/algemeen-taxatie";
import BouwkundigeKeuring from "./bouwkundige-keuring/bouwkundige-keuring";
import Contactpersoon from "./contactpersoon/contactpersoon";
import { determineTaxatieAsyncSideEffects } from "./determine-taxatie-async-side-effects";
import { syncTaxatieSideEffects } from "./determine-taxatie-sync-side-effects";
import { getTaxatieResources } from "./infra/taxatie-resources";
import {
  TaxatieKenmerkenType,
  TaxatieSchermProps,
  taxatieSchermSchema,
  TaxatieSchermType
} from "./infra/taxatie-scherm-schema";
import ObjectTaxatie from "./object-taxatie/object-taxatie";
import OpdrachtgeverTaxatie from "./opdrachtgever-taxatie/opdrachtgever-taxatie";
import Partner from "./partner-taxatie/partner-taxatie";
import TaxatieAanvragen from "./taxatie-aanvragen/taxatie-aanvragen";
import Taxatiebureau from "./taxatiebureau/taxatiebureau";
import { WithSaveData } from "../shared/utils/save-data";

const Taxatie = (props: FormikContextType<TaxatieSchermType> & TaxatieSchermProps): ReactElement => {
  const { setSErunning } = useContext(AppDataContext);
  const { isSubmitting, values, taxatiebureauOpties } = props;
  const maatschappijCode = values.taxatiebureau?.maatschappijCode;
  const kenmerken = values.taxatieKenmerken as TaxatieKenmerkenType;
  const contactpersoonTonen = kenmerken?.KaartContactpersoonTonen;
  const bouwkundigeKeuringTonen = kenmerken?.KaartBouwkundigeKeuringTonen;
  const partnerTonen = values.aanvrager2 && kenmerken?.KaartPartnerTonen;


  return (
    <FormFirstFocus>
      <Form>
        {(isSubmitting || values.isLoading) && <PageLoading />}

        <ISWSideEffects<TaxatieSchermType>
          sync={syncTaxatieSideEffects()}
          async={determineTaxatieAsyncSideEffects()}
          asyncStartStopCallback={setSErunning}
        />

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Taxatie</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper className="w-75 px-3">
          <Card title="Taxatiebureau">
            <Taxatiebureau taxatiebureauOpties={taxatiebureauOpties} />
          </Card>
          {maatschappijCode && (
            <>
              {kenmerken?.KaartObjectTonen && (
                <Card title="Object">
                  <ObjectTaxatie />
                </Card>
              )}
              {kenmerken?.KaartTaxatieTonen && (
                <Card title={ getTaxatieResources("Taxatie")}>
                  <AlgemeenTaxatie />
                </Card>
              )}
              <Card title="Opdrachtgever">
                <OpdrachtgeverTaxatie />
              </Card>
              {partnerTonen && (
                <Card title="Partner">
                  <Partner />
                </Card>
              )}

              {contactpersoonTonen && (
                <Card title="Contactpersoon">
                  <Contactpersoon />
                </Card>
              )}
              {bouwkundigeKeuringTonen && (
                <Card title="Bouwkundige keuring">
                  <BouwkundigeKeuring />
                </Card>
              )}
              {kenmerken?.KaartAanvullendeInformatieTonen && (
                <Card title="Aanvullende informatie">
                  <AanvullendeInformatie />
                </Card>
              )}
              <Card title="Taxatie aanvragen">
                <TaxatieAanvragen saveData={props.saveData} />
              </Card>
            </>
          )}
        </CardWrapper>

        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

Taxatie.displayName = "Taxatie";

export default withAdviesboxFormik<TaxatieSchermProps  & WithSaveData<TaxatieSchermType>, TaxatieSchermType>({
  mapPropsToValues: (e: TaxatieSchermProps): TaxatieSchermType => e,
  validationSchema: taxatieSchermSchema
})(Taxatie);
