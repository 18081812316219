import { useState, useEffect } from 'react';

const hasJSONResponse = (response: Response):boolean => {
  const contentType:string|null = response.headers.get("content-type");
  return !!(contentType && contentType.includes("application/json"));
};

type FetchDataReturn<T> = {
  data: T | undefined;
  loading: boolean;
  error: string | null;
}

 export const useFetchData = <T,>(url: string,  requestInit: RequestInit): FetchDataReturn<T> => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchData = async ():Promise<void> => {
      try {
        setLoading(true);

        const response = await fetch(url, requestInit);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        if (!hasJSONResponse(response)) {
          throw new Error(`Error fetching data from ${url}.`);
        }

        const result: T = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [url, requestInit]);

  return { data, loading, error };
};

