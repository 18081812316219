import { useContext } from "react";
import { FeatureContext } from "./feature-context";

export const useFeature = (featureKey: string): boolean => {
  const { features } = useContext(FeatureContext);

  if (!features) {
    return false;
  }

  return !!features[featureKey];
};
