import React, { useMemo } from "react";
import { LineChart, useRequestInit, LoadingSpinner } from "adviesbox-shared";
import { useFetchData } from "../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata";
import { LastenOverzicht } from "../../../.generated/rapportage/rapportagetypes";

type LastenOverzichtChartProps = {
  isVoorstel: boolean;
};

const LastenOverzichtChart: React.FC<LastenOverzichtChartProps> = ({ isVoorstel }: LastenOverzichtChartProps) => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const getLastenOverzichtUrl = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return isVoorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/LastenOverzicht`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht`;
  }, [settings.rapportageOrigin, params.adviesdossier, params.voorstel, isVoorstel]);

  const fetchConfig = useMemo(
    () => ({
      url: getLastenOverzichtUrl,
      config: { ...requestInit, method: "POST" }
    }),
    [getLastenOverzichtUrl, requestInit]
  );

  const { data, loading, error } = useFetchData<LastenOverzicht>(fetchConfig.url, fetchConfig.config);

  if (error) return <div>Error: {error}</div>;
  if (!data || loading) return <LoadingSpinner />;
  if (!data.jaarGegevens) return <div>Geen lastenoverzicht gegevens beschikbaar</div>;

  return (
    <div className="p-2" data-testid="line-chart">
      <LineChart
        labels={data.jaarGegevens.map(c => c.jaar.toString())}
        optionsObject={{
          layout: {
            padding: 20
          }
        }}
        datasets={[
          {
            label: "Bruto maandlasten",
            data: data.jaarGegevens.map(c => c.brutoLast),
            backgroundColor: "#DF2935",
            borderColor: "#DF2935",
            borderWidth: 3,
            radius: 0,
          },
          {
            label: "Netto maandlasten",
            backgroundColor: "#C2F970",
            borderColor: "#C2F970",
            data: data.jaarGegevens.map(c => c.nettoLast),
            borderWidth: 3,
            radius: 0,
          }
        ]}
      />
    </div>
  );
};

export default LastenOverzichtChart;
