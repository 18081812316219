import React, { createContext, ReactElement, ReactNode, useCallback, useState } from "react";
import { VoorstelKeuzeType } from "adviesbox-shared";
import { MenuInfo } from "../.generated/forms/formstypes";
import { SubmitResultTypes } from "../shared/utils/save-validation";

type AppDataProviderProps = {
  children: ReactNode;
};

type SaveContextType = {
  saveDataFn: (preventReloadNavigation?: boolean) => Promise<SubmitResultTypes | null>;
  // preventReload: boolean;
};

export type AppDataContextType = {
  reloadCount: number;
  reloadNavigation: () => void;
  menuInfo: MenuInfo;
  setMenuInfo: React.Dispatch<React.SetStateAction<MenuInfo>>;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
  voorstellen: VoorstelKeuzeType[];
  setBeschikbareVoorstellen: React.Dispatch<React.SetStateAction<VoorstelKeuzeType[]>>;
  error: Error | null;
  setError: React.Dispatch<React.SetStateAction<Error | null>>;
  seRunning: boolean;
  setSErunning: React.Dispatch<React.SetStateAction<boolean>>;
  saveContext: SaveContextType | null;
  setSaveContext: React.Dispatch<React.SetStateAction<SaveContextType | null>>;
  lastMutationDate: string | null;
  setLastMutationDate: React.Dispatch<React.SetStateAction<string | null>>;
};

export const AppDataContext = createContext({
  reloadCount: 0,
  reloadNavigation: () => 0,
  menuInfo: {} as MenuInfo,
  setMenuInfo: () => { },
  activePage: "",
  setActivePage: () => { },
  voorstellen: [],
  setBeschikbareVoorstellen: () => [],
  error: null,
  setError: () => null,
  seRunning: false,
  setSErunning: () => { },
  saveContext: null,
  setSaveContext: () => null,
  lastMutationDate: null,
  setLastMutationDate: () => { }
} as AppDataContextType);

export const AppDataProvider = ({ children }: AppDataProviderProps): ReactElement => {
  const [reloadNavigationCounter, setReloadNavigationCounter] = useState(1);
  const [error, setError] = useState<Error | null>(null);
  const reloadNavigation = useCallback(() => setReloadNavigationCounter(oldState => oldState + 1), [
    setReloadNavigationCounter
  ]);
  const [activePage, setActivePage] = useState("");
  const [menuInfo, setMenuInfo] = useState({} as MenuInfo);
  const [voorstellen, setBeschikbareVoorstellen] = useState<VoorstelKeuzeType[]>([]);
  const [seRunning, setSErunning] = useState(false);
  const [saveContext, setSaveContext] = useState<SaveContextType | null>(null);
  const [lastMutationDate, setLastMutationDate] = useState<string | null>(null);

  return (
    <AppDataContext.Provider
      value={{
        error,
        setError,
        reloadCount: reloadNavigationCounter,
        reloadNavigation: reloadNavigation,
        menuInfo,
        setMenuInfo,
        activePage,
        setActivePage,
        voorstellen,
        setBeschikbareVoorstellen,
        seRunning,
        setSErunning,
        saveContext,
        setSaveContext,
        lastMutationDate,
        setLastMutationDate,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
