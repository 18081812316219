/*istanbul ignore file*/
import { useRequestInit, validationResultsToUiError, setFormikUiErrors } from "adviesbox-shared";
import { connect, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { aanvraagVerzenden } from "../infra/aanvragen-api";
import { BankgarantieState } from "../infra/bankgarantie-schema";
import { mapBankgarantieAanvraagToDl } from "../infra/map-bankgarantie-ui-to-dl";
import { SaveButton } from "../../shared/components/save-button/save-button";
import { WithSaveData } from "../../shared/utils/save-data";
import { UiError } from "adviesbox-shared/utils/types";
import { target2field } from "../../shared/utils/target-to-field";

const Aanvragen = ({ saveData }: { saveData:  (values: BankgarantieState, preventReload?: boolean | undefined) => Promise<UiError[] | null> }): ReactElement => {
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const formik = useFormikContext<BankgarantieState>();
  const { values, setFieldValue } = formik;

  const bankgarantieAanvraag = React.useMemo(() => mapBankgarantieAanvraagToDl(values), [values]);
  const [aanvraagVerzonden, setAanvraagVerzonden] = React.useState(false);
  const [aanvragenBtnUsed, setAanvragenBtnUsed] = React.useState(false);


  return (
    <>
      <div className="button-container mx-5 d-flex justify-content-center" id={`bankgarantie-aanvragen-button`}>
        {aanvraagVerzonden && (<p>Uw aanvraag is verzonden. <button onClick={()=>{setAanvraagVerzonden(false)}} type="button">Reset</button></p>)}
        {!aanvraagVerzonden && 
        
        /* Van deze hidden div gebruiken we de onClick om te bepalen of de gebruiker op deze knop heeft geklikt 
           en dus niet op de SaveButton die onderin het scherm staat. Hiermee voorkomen we dat bij een normale submit/navigatie, de callback wordt uitgevoerd.
        */
        <div onClick={()=> setAanvragenBtnUsed(true)} >
        <SaveButton
          context={{ ...formik, saveData: saveData, dirty: true }}
          callBack={async () => {
            if (!aanvragenBtnUsed) return;
            const resultaat =  await aanvraagVerzenden(
              settings,
              user,
              params?.vestiging,
              params?.adviesdossier,
              bankgarantieAanvraag,
              setFieldValue
            );


            if (typeof resultaat!= "string" && resultaat.validationResults?.length) {
              const uiErrors: UiError[] = validationResultsToUiError(resultaat.validationResults, (target:string)=> target2field([],target));
              setFormikUiErrors(uiErrors, formik);   
              return;
            }

            if (typeof resultaat !== "string" && resultaat.url) {
              const url = resultaat.url;
              window.open(url, "_blank");
            }

            setAanvraagVerzonden(true);

            return resultaat;
          }}
          showSaveRequest
        />
        </div>
        }
        
      </div>
    </>
  );
};

export default connect<WithSaveData<BankgarantieState>, BankgarantieState>(Aanvragen);
