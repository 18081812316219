import React, { ReactElement } from "react";
import { connect, FormikContextType } from "formik";

import { CheckBox, LabeledBevestigingInput } from "adviesbox-shared";
import { AanvraagKenmerken } from "../infra/product-kenmerken-types";
import { getNaam } from "../../shared/utils/helpers";
import { ProductenState } from "../infra/producten-overzicht-types";
import { getProductenOverzichtTextResources } from "../infra/producten-overzicht-resources";
import { AanvragerKeuze } from "../../shared/types";

type InformatieVoorzieningProps = {
  selected: number;
  kenmerken: AanvraagKenmerken;
};

const InformatieVoorVerzendingAanvraag = ({
  selected,
  kenmerken,
  formik: {
    values: { producten, aanvrager1, aanvrager2 }
  }
}: InformatieVoorzieningProps & {
  formik: FormikContextType<ProductenState>;
}): ReactElement => {
  const verzekerdenBeiden = producten[selected].verzekerden.verzekerden === AanvragerKeuze.Beiden;
  const verzekerdenAanvrager1 = producten[selected].verzekerden.verzekerden === AanvragerKeuze.Aanvrager1;
  const verzekerdenAanvrager2 = producten[selected].verzekerden.verzekerden === AanvragerKeuze.Aanvrager2;

  return (
    <>
      {kenmerken.sprakeVanStrafrechtelijkVerledenTonen && (verzekerdenBeiden || verzekerdenAanvrager1) && (
        <>
          <h3 className="ml-3 mt-2">{getNaam(aanvrager1, "Aanvrager 1")}</h3>
          {kenmerken.sprakeVanStrafrechtelijkVerledenTonen && (
            <LabeledBevestigingInput
              caption="Is er sprake van een strafrechtelijk verleden?"
              name={`producten[${selected}].informatieVoorVerzendingAanvraag.strafrechtelijkVerledenAanvrager1`}
            />
          )}
        </>
      )}
      {kenmerken.sprakeVanStrafrechtelijkVerledenTonen && (verzekerdenBeiden || verzekerdenAanvrager2) && (
        <>
          <h3 className="ml-3 mt-2">{getNaam(aanvrager2, "Aanvrager 2")}</h3>
          {kenmerken.sprakeVanStrafrechtelijkVerledenTonen && (
            <LabeledBevestigingInput
              caption="Is er sprake van een strafrechtelijk verleden?"
              name={`producten[${selected}].informatieVoorVerzendingAanvraag.strafrechtelijkVerledenAanvrager2`}
            />
          )}
        </>
      )}
      <div className="pt-3">
        {kenmerken.akkoordMetDigitaleCommunicatieTonen && (
          <LabeledBevestigingInput
            caption="Digitale communicatie"
            name={`producten[${selected}].informatieVoorVerzendingAanvraag.akkoordMetDigitaleCommunicatie`}
          />
        )}

        {kenmerken.akkoordMetDigitalePolisTonen && (
          <LabeledBevestigingInput
            caption="Digitale polis"
            name={`producten[${selected}].informatieVoorVerzendingAanvraag.akkoordMetDigitalePolis`}
          />
        )}
        {kenmerken.toelichtingGelezenAkkoordMetSlotverklaringTonen && (
          <div className="mx-1-35">
            <CheckBox
              caption="Toelichting gelezen en slotverklaring akkoord"
              name={`producten[${selected}].informatieVoorVerzendingAanvraag.toelichtingGelezenAkkoordMetSlotverklaring`}
              tooltip={getProductenOverzichtTextResources(
                "informatieVoorVerzendingAanvraagToelichtingGelezenEnSlotverklaring"
              )}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default connect<InformatieVoorzieningProps, ProductenState>(InformatieVoorVerzendingAanvraag);
