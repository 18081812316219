import { ReactElement } from "react";
import { Column } from "react-table-6";
import { createSpanWithId } from "adviesbox-shared";
import moment from "moment";

export const externeKoppelingenDossiersColumns: Column[] = [
  {
    Header: "Import datum",
    accessor: "importDatum",
    id: "importDatum",
    Cell: (c): ReactElement => {
      const val = moment(c.original.importDatum).format("DD-MM-YYYY HH:mm");
      return createSpanWithId(c.index, 0, val, val, "externe-koppelingen");
    }
  },
  {
    Header: "Koppeling naam",
    accessor: "koppelingNaam",
    id: "koppelingNaam",
    Cell: (c): ReactElement => {
      return createSpanWithId(c.index, 0, c.original.koppelingNaam, c.original.koppelingNaam, "externe-koppelingen");
    }
  },
  {
    Header: "Klant naam",
    accessor: "klantNaam",
    id: "klantNaam",
    Cell: (c): ReactElement => {
      return createSpanWithId(c.index, 0, c.original.klantNaam, c.original.klantNaam, "externe-koppelingen");
    }
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
    Cell: (c): ReactElement => {
      return createSpanWithId(c.index, 0, c.original.status, c.original.status, "externe-koppelingen");
    }
  }
];
