import { Icon, ModalButton, SettingsType, useRequestInit, FetchDataButton } from "adviesbox-shared";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { ReactElement, useContext, useRef } from "react";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { WithSaveData } from "../shared/utils/save-data";
import { submit } from "../shared/utils/save-validation";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { Tekort } from "./components/tekort/tekort";
import classes from "./dashboard.module.scss";
import { berekenScenarios } from "./infra/bereken-scenarios-api";
import DashboardContext from "./infra/dashboard-context";
import { dashboardSchema, tekortSchema, uitgangspuntenSchema } from "./infra/dashboard-schema";
import { dashboardSyncSideEffects } from "./infra/dashboard-sync-side-effects";
import { DashboardProps, DashboardState } from "./infra/dashboard-types";
import { mapDashboardDlToUi } from "./infra/map-dashboard-dl-2-ui";
import { ScenarioArbeidsongeschiktheidModal } from "./scenario-arbeidsongeschiktheid-modal/scenario-arbeidsongeschiktheid-modal";
import { ScenarioOverlijdenModal } from "./scenario-overlijden-modal/scenario-overlijden-modal";
import { ScenarioPensioenModal } from "./scenario-pensioen-modal/scenario-pensioen-modal";
import { ScenarioWerkloosheidModal } from "./scenario-werkloosheidheid-modal/scenario-werkloosheid-modal";

export const DashboardComponent = ({ setBerekend, situatie, saveData, formikParent }: DashboardProps): ReactElement => {
  const { dashboardValues, setDashboardValues } = useContext(DashboardContext);
  const formik = useFormikContext<DashboardState>();

  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const fetchBtnRef = useRef<HTMLInputElement | null>(null);

  /* istanbul ignore next */
  const createUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Dashboard`;

  const createUrlVoorstel = (s: SettingsType, p: RouteParams): string =>
    `${s.klantdossiersFormsOrigin}/Voorstellen/${p.voorstel}/Dashboard`;

  /* istanbul ignore next */
  const fetchData = () => async (): Promise<void> => {
    const version = formik.values.version;
    // submit the main screen where the dashboard is used on (ie. 'Hypotheek' or 'Orv' page).
    if (formikParent.dirty) {
      const submitResult = await submit(saveData, formikParent);
      if (submitResult !== "completed") {
        return;
      }
    }

    setBerekend && setBerekend(true);
    setDashboardValues({ ...dashboardValues, berekenenFailed: false });

    formik.setErrors({});

    const res = await berekenScenarios(
      situatie === "voorstel" ? createUrlVoorstel(settings, params) : createUrl(settings, params),
      requestInit
    );

    if (typeof res === "string" || !res[0]) {
      const error = typeof res === "string" ? res : "geen gegevens";
      formik.setErrors({ initErrors: error });
      setDashboardValues({ ...dashboardValues, berekenenFailed: true });
      return;
    }

    const berekendeResultatendashboard = mapDashboardDlToUi(res[0]);

    const newValues: DashboardState = {
      ...formik.values,
      ...berekendeResultatendashboard,
      // init van dashboard behouden:
      overlijdenWeergeven: formik.values.overlijdenWeergeven,
      arbeidsongeschiktheidWeergeven: formik.values.arbeidsongeschiktheidWeergeven,
      pensioenWeergeven: formik.values.pensioenWeergeven,
      werkloosheidWeergeven: formik.values.werkloosheidWeergeven,
      version: version
    };

    formik.setValues(newValues);
  };
  const updateDashboardFormikValues = (values: DashboardState): void => {
    setDashboardValues({ ...dashboardValues, formikValues: values });
  };
  const switchCollapsed = (): void => {
    setDashboardValues({ ...dashboardValues, collapsed: !dashboardValues.collapsed });
  };
  const updateVersion = (): void => {
    const newValue = formik.values.version === undefined ? 0 : (formik.values.version ?? 0) + 1;
    formik.setFieldValue("version", newValue);
  };
  const hasOnderwerpen =
    dashboardValues.initialized &&
    (formik.values.arbeidsongeschiktheidWeergeven ||
      formik.values.overlijdenWeergeven ||
      formik.values.pensioenWeergeven ||
      formik.values.werkloosheidWeergeven);
  return (
    <>
      <ISWSideEffects
        sync={dashboardSyncSideEffects({
          setDashboardValues: values => {
            updateDashboardFormikValues(values);
          },
          updateVersion
        })}
      />
      <div className={classNames("sidebar_dashboard", { collapsed: dashboardValues.collapsed }, "dashboard-content")}>
        <div className="menu-minimize" data-testid="menu-minimize" onClick={(): void => switchCollapsed()}>
          <div className="drawer">
            <Icon name="hamburger" />
          </div>
        </div>
        <div data-testid="sidebar-dashboard-container" className={classNames("sidebar_dashboard", { collapsed: dashboardValues.collapsed })}>
          <div className={classNames("dashboard")}>
          {!dashboardValues.collapsed && (
            <>
              <h1 className="sidebar_dashboard__title">Scenario&apos;s</h1>
              <div className="sidebar_dashboard__btn">
                <FetchDataButton
                  onClick={fetchData()}
                  invalid={false}
                  dataOutDated={true}
                  keepVisible={true}
                  initialText={"Scenario’s berekenen"}
                  hasResult={false}
                  className={classes.btn_fetch_small}
                  id={"btn-fetch-dashboard"}
                  btnRef={fetchBtnRef}
                />
              </div>
            </>
          )}
            {formik.errors.initErrors ? (
              <p data-testid="initErrors">{formik.errors.initErrors}</p>
            ) : (
              <>
                {dashboardValues.berekenenFailed && !dashboardValues.collapsed && (
                  <p className={classNames(classes.api_error)}>
                    Er is een fout opgetreden tijdens het berekenen van de scenario gegevens.
                  </p>
                )}

                {!hasOnderwerpen && <p data-testid="geen-onderwerpen">Geen onderwerpen geselecteerd.</p>}

                {hasOnderwerpen && formik.values.overlijdenWeergeven && (
                  <div className="scenario-block">
                    <ModalButton
                      className={classNames(classes.head_text, "head-text")}
                      parent={"dummy"}
                      content={dashboardValues.collapsed ? "ORV" : "Overlijden"}
                      size="lg"
                      distinguisher={"overlijden"}
                      disableDnD={true}
                    >
                      <ScenarioOverlijdenModal
                        callbackOnClose={
                          /* istanbul ignore next */
                          () => {
                            fetchBtnRef.current?.click();
                          }
                        }
                      />
                    </ModalButton>
                    {!dashboardValues.collapsed && (
                      <Tekort
                        tekort={formik.values.overlijdenTekort || /* istanbul ignore next */ tekortSchema.default()}
                        uitgangspunten={
                          formik.values.overlijdenUitgangspunten ||
                          /* istanbul ignore next */ uitgangspuntenSchema.default()
                        }
                        aanvrager={formik.values.aanvrager1}
                        partner={formik.values.aanvrager2}
                      />
                    )}
                  </div>
                )}
                {hasOnderwerpen && formik.values.arbeidsongeschiktheidWeergeven && (
                  <div className="scenario-block">
                    <ModalButton
                      className={classNames(classes.head_text, "head-text")}
                      parent={"dummy"}
                      content={"AO"}
                      size="lg"
                      distinguisher={"ao"}
                      disableDnD={true}
                    >
                      <ScenarioArbeidsongeschiktheidModal
                        callbackOnClose={
                          /* istanbul ignore next */ () => {
                            fetchBtnRef.current?.click();
                          }
                        }
                      />
                    </ModalButton>
                    {!dashboardValues.collapsed && (
                      <Tekort
                        tekort={
                          formik.values.arbeidsongeschiktheidTekort ||
                          /* istanbul ignore next */ tekortSchema.default()
                        }
                        uitgangspunten={
                          formik.values.arbeidsongeschiktheidUitgangspunten ||
                          /* istanbul ignore next */ uitgangspuntenSchema.default()
                        }
                        aanvrager={formik.values.aanvrager1}
                        partner={formik.values.aanvrager2}
                        beidenTonen={true}
                      />
                    )}
                  </div>
                )}
                {hasOnderwerpen && formik.values.werkloosheidWeergeven && (
                  <div className="scenario-block">
                    <ModalButton
                      className={classNames(classes.head_text, "head-text")}
                      parent={"dummy"}
                      content={"WW"}
                      size="lg"
                      distinguisher={"ww"}
                      disableDnD={true}
                    >
                      <ScenarioWerkloosheidModal
                        callbackOnClose={
                          /* istanbul ignore next */ () => {
                            fetchBtnRef.current?.click();
                          }
                        }
                      />
                    </ModalButton>
                    {!dashboardValues.collapsed && (
                      <Tekort
                        tekort={formik.values.werkloosheidTekort || /* istanbul ignore next */ tekortSchema.default()}
                        uitgangspunten={
                          formik.values.werkloosheidUitgangspunten ||
                          /* istanbul ignore next */ uitgangspuntenSchema.default()
                        }
                        aanvrager={formik.values.aanvrager1}
                        partner={formik.values.aanvrager2}
                        beidenTonen={true}
                      />
                    )}
                  </div>
                )}
                {hasOnderwerpen && formik.values.pensioenWeergeven && (
                  <div className="scenario-block">
                    <ModalButton
                      className={classNames(classes.head_text, "head-text")}
                      parent={"dummy"}
                      content={dashboardValues.collapsed ? "PW" : "Pensioen"}
                      size="lg"
                      distinguisher={"pensioen"}
                      disableDnD={true}
                    >
                      <ScenarioPensioenModal
                        callbackOnClose={
                          /* istanbul ignore next */ () => {
                            fetchBtnRef.current?.click();
                          }
                        }
                      />
                    </ModalButton>

                    {!dashboardValues.collapsed && (
                      <Tekort
                        tekort={formik.values.pensioenTekort || /* istanbul ignore next */ tekortSchema.default()}
                        uitgangspunten={
                          formik.values.pensioenUitgangspunten ||
                          /* istanbul ignore next */ uitgangspuntenSchema.default()
                        }
                        aanvrager={formik.values.aanvrager1}
                        partner={formik.values.aanvrager2}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardWithFormik = withAdviesboxFormik<DashboardProps & WithSaveData<any>, DashboardState>({
  mapPropsToValues: (e: DashboardProps): DashboardState => e.formikValues ?? dashboardSchema.default(),
  validationSchema: dashboardSchema
})(DashboardComponent);

export const Dashboard = (props: DashboardProps & WithSaveData<any>): ReactElement => {
  const { dashboardValues } = useContext(DashboardContext);
  if (!dashboardValues.initialized) return <></>;
  props = { ...props, formikValues: dashboardValues.formikValues };
  return (
    <DashboardWithFormik
      saveData={props.saveData}
      situatie={props.situatie}
      formikParent={props.formikParent}
      formikValues={props.formikValues}
    />
  );
};
