import { SettingsType, useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import { useCallback, useContext, useEffect, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { AbortableFetchResult } from "use-abortable-fetch/release/types";
import { AppDataContext } from "../../navigation/appdata-context";
import { UiError, UiName } from "../types";
import { saveDataRequestInit as createSaveData } from "../utils/save-data";

const noop = async (): Promise<null> => null;

const noUserResult = {
  loading: false,
  error: new Error("Missing AuthContext"),
  data: null,
  platformData: null,
  saveData: noop
};

const invalidDataResult = {
  loading: false,
  error: new Error("Invalid data"),
  data: null,
  platformData: null,
  saveData: noop
};

const invalidDataIdResult = {
  loading: false,
  error: new Error("Invalid dataId"),
  data: null,
  platformData: null,
  saveData: noop
};

type UseAdviesboxDataReturnType<DL, UI> = Omit<AbortableFetchResult<DL>, "data"> & {
  platformData: AbortableFetchResult<DL>["data"];
  data: UI | null;
  saveData: (values: UI, preventReloadNavigation?: boolean) => Promise<UiError[] | null>;
  params: ReturnType<typeof useRequestInit>["params"];
};

export const useAdviesboxData = <DL, P extends ReturnType<typeof useRequestInit>["params"], UI>(
  getUrl: (settings: SettingsType, params: P) => string,
  getDataId: (params: P) => string | undefined,
  mapDlToUi: (dataId: string, values: DL) => UI | null,
  mapUiToDl: (values: UI) => any,
  mapDlTarget: (target: string) => UiName | null,
  preventNavigationReload?: boolean
): UseAdviesboxDataReturnType<DL, UI> => {
  const loadingDone = useContext(ForceRerenderContext);
  const { reloadNavigation } = useContext(AppDataContext);
  const { requestInit, settings, params, user } = useRequestInit<P>();

  const url = useMemo(() => getUrl(settings, params), [getUrl, settings, params]);
  const dataId = useMemo(() => getDataId(params), [getDataId, params]);

  const { loading: platformLoading, error: platformError, data: platformData, abort } = useAbortableFetch<DL>(
    url,
    requestInit
  );
  const loading = platformLoading || (!platformLoading && !platformError && !platformData);

  useEffect((): void => {
    if (!platformLoading && platformData && loadingDone) {
      loadingDone();
    }
  }, [platformLoading, platformData, loadingDone]);

  const saveData = useCallback(
    user
      ? createSaveData(url, requestInit, mapUiToDl, mapDlTarget, preventNavigationReload ? () => {} : reloadNavigation)
      : noop,
    [user, params.vestiging, url, settings.OcpApimSubscriptionKey, mapUiToDl, mapDlTarget]
  );
  const data = useMemo(
    () => (dataId && platformData && typeof platformData !== "string" ? mapDlToUi(dataId, platformData) : null),
    [dataId, platformData, mapDlToUi]
  );

  if (!user) {
    abort();
    return {
      ...noUserResult,
      abort,
      params
    };
  }

  if (!dataId) {
    abort();
    return {
      ...invalidDataIdResult,
      abort,
      params
    };
  }

  if (typeof platformData === "string") {
    return {
      ...invalidDataResult,
      abort,
      params
    };
  }
  const error =
    platformData && !data && !platformError ? new Error(`Unable to map platform data for dataId: ${dataId}`) : null;

  return { loading, error: platformError || error, data, platformData, saveData, abort, params };
};
