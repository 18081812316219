import React, { ReactElement } from "react";
import { Form, FormikProps } from "formik";
import { Card, CardWrapper, PageLoading } from "adviesbox-shared";
import VergoedingenOverzichtCard from "./vergoedingen-overzicht-details/vergoedingen-overzicht-details";
import HuidigeProductenCard from "./huidige-producten/huidige-producten";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import HuidigeProductenModal from "./huidige-producten-modal/huidige-producten-modal";
import vergoedingenOverzichtSchema, {
  VergoedingenOverzichtState,
  VergoedingenOverzichtProps
} from "./infra/vergoedingen-overzicht-schema";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { Icon, FormFirstFocus } from "adviesbox-shared";
import { SaveButton } from "../shared/components/save-button/save-button";
import { ModalButton } from "adviesbox-shared";
import { determineVergoedingenOverzichtSideEffects } from "./infra/determine-vergoedingen-overzicht-side-effects";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";

const VergoedingenOverzicht = (
  props: FormikProps<VergoedingenOverzichtState> & VergoedingenOverzichtProps
): ReactElement => {
  const { values, isSubmitting } = props;

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}

        <ISWSideEffects<VergoedingenOverzichtState> sync={determineVergoedingenOverzichtSideEffects()} />

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Uitgangspunten</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <CardWrapper className="px-3">
          <Card title="Vergoedingenoverzicht">
            <VergoedingenOverzichtCard />
          </Card>
          {values.huidigProducten.length > 0 && (
            <Card
              title="Huidige producten"
              titleAppend={
                <ModalButton
                  parent="huidigProductKenmerken"
                  size="lg"
                  content={<Icon name="viewmodal" alt="Huidige producten popup" />}
                >
                  <HuidigeProductenModal data={values.huidigProductKenmerken} />
                </ModalButton>
              }
            >
              <HuidigeProductenCard
                producten={values.huidigProducten}
                ingangsdatum={values.vergoedingenOverzicht.ingangsdatum}
              />
            </Card>
          )}
        </CardWrapper>
        <DevDebug />
      </Form>
    </FormFirstFocus>
  );
};

VergoedingenOverzicht.displayName = "VergoedingenOverzicht";

export default withAdviesboxFormik<VergoedingenOverzichtProps, VergoedingenOverzichtState>({
  mapPropsToValues: (e: VergoedingenOverzichtProps): VergoedingenOverzichtState => e,
  validationSchema: vergoedingenOverzichtSchema
})(VergoedingenOverzicht);
