/* istanbul ignore file */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useRequestInit, sleep, LoadingSpinner } from "adviesbox-shared";
import { DashboardOutput } from "../../../.generated/forms/formstypes";

export const fetchData = async (url: string, requestinit:RequestInit, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<any> => {
  await sleep(3000);
  setLoading(true);
  const response = await fetch(url, requestinit);

  if (!response.ok) throw new Error("Network response was not ok");
  return response.json();
};

export const Loading: React.FC = () => <div>Loading...</div>;

interface DataDisplayProps {
  endpoint: string;
  render: (data: DashboardOutput) => React.ReactNode;
  isVoorstel?: boolean;
}

export const DataDisplay: React.FC<DataDisplayProps> = ({ endpoint, render,isVoorstel }) => {

  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const getLastenOverzichtUrl = useMemo(() => {
    const baseUrl = `${settings.klantdossiersFormsOrigin}`;
    return isVoorstel ? `${baseUrl}/Voorstellen/${params.voorstel}/Dashboard/${endpoint}` : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Dashboard/${endpoint}`
  }, [ params.adviesdossier, params.voorstel, isVoorstel, endpoint, settings.klantdossiersFormsOrigin]);


  const [data, setData] = useState<DashboardOutput | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setError] = useState<string | null>(null);
  useEffect(() => {
    fetchData(getLastenOverzichtUrl,requestInit, setLoading)
      .then(result => {
        setData(result);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [endpoint, getLastenOverzichtUrl, requestInit]);

  if (loading) return <LoadingSpinner size="S"/>;
  if (errors) return <div>Er is een fout opgetreden bij het ophalen van de gegevens.</div>;
  // if (data==null && errors==null) return <></>;
  return <>{ !!data ? render(data) :<></>}</>;
};
