import classNames from "classnames";
import React, { ReactElement } from "react";
import { ControleGewenstInkomenOptions, DashboardTekort } from "../../../.generated/forms/formstypes";
import { KlantnaamType } from "../../../shared/generic-parts/klantnaam/schema";
import { bedragFormat } from "../../../shared/utils/currency";
import { hasValue } from "../../../shared/utils/helpers";
import { UitgangspuntenType } from "../../infra/dashboard-types";

type TekortProps = {
  tekort: DashboardTekort;
  uitgangspunten: UitgangspuntenType;
  beidenTonen?: boolean;
  aanvrager: KlantnaamType;
  partner: KlantnaamType | null;
};

export const Tekort = ({ tekort, uitgangspunten, beidenTonen, aanvrager, partner }: TekortProps): ReactElement => {
  const hypotheekBedragenTonen = uitgangspunten.controleHypotheeknorm?.controleerMaximaleHypotheek;
  const inkomensBedragenTonen =
    uitgangspunten.controleHypotheeknorm?.controleMaandlast ||
    uitgangspunten.controleGewenstInkomen?.controleGewenstInkomen !== ControleGewenstInkomenOptions.Geen;

  const aanvragerTonen = uitgangspunten.scenarioVanKlantIds.some(c => c === aanvrager.klantId);
  const partnerTonen = partner && uitgangspunten.scenarioVanKlantIds.some(c => c === partner.klantId);
  const beideTonen = beidenTonen && uitgangspunten.scenarioVanKlantIds.length === 2;

  /* indien zowel controle op maximale hypotheek alsmede maandlast, controle-inkomen, dan dus voorrang op tekort inkomen.*/
  return (
    <>
      {hypotheekBedragenTonen && (
        <div data-testid="hypotheek-tekort">
          {aanvragerTonen && (
            <span className={classNames("tekort")}>
              {hasValue(tekort.tekortHypotheek1Bedrag) && `A: ${bedragFormat(tekort.tekortHypotheek1Bedrag)}`}
              {!hasValue(tekort.tekortHypotheek1Bedrag) && `A: -`}
            </span>
          )}
          {partnerTonen && (
            <span className={classNames("tekort")}>
              {hasValue(tekort.tekortHypotheek2Bedrag) && `P: ${bedragFormat(tekort.tekortHypotheek2Bedrag)}`}
              {!hasValue(tekort.tekortHypotheek2Bedrag) && `P: -`}
            </span>
          )}
          {beideTonen && (
            <span className={classNames("tekort")}>
              {hasValue(tekort.tekortHypotheekBeideBedrag) && `B: ${bedragFormat(tekort.tekortHypotheekBeideBedrag)}`}
              {!hasValue(tekort.tekortHypotheekBeideBedrag) && `B: -`}
            </span>
          )}
        </div>
      )}
      {inkomensBedragenTonen && (
        <div data-testid="inkomen-tekort">
          {aanvragerTonen && (
            <span className={classNames("tekort")}>
              {hasValue(tekort.tekortInkomen1Bedrag) && `A: ${bedragFormat(tekort.tekortInkomen1Bedrag / 12)} p.m.`}
              {!hasValue(tekort.tekortInkomen1Bedrag) && `A: - p.m.`}
            </span>
          )}
          {partnerTonen && (
            <span className={classNames("tekort")}>
              {hasValue(tekort.tekortInkomen2Bedrag) && `P: ${bedragFormat(tekort.tekortInkomen2Bedrag / 12)} p.m.`}
              {!hasValue(tekort.tekortInkomen2Bedrag) && `P: - p.m.`}
            </span>
          )}
          {beideTonen && (
            <span className={classNames("tekort")}>
              {hasValue(tekort.tekortInkomenBeideBedrag) &&
                `B: ${bedragFormat(tekort.tekortInkomenBeideBedrag / 12)} p.m.`}
              {!hasValue(tekort.tekortInkomenBeideBedrag) && `B: - p.m.`}
            </span>
          )}
        </div>
      )}
    </>
  );
};
