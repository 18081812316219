/* istanbul ignore file */
import { AuthContext, ErrorMessageBox, getNaamAdviseur, useUpscope } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useRef } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Drawer from "../shared/components/drawer/Drawer";
import { Title } from "../shared/components/title/title";
import TopNavigation from "../shared/components/topnav-dossier/TopNavigation";
import OpenDossierLogContext from "../shared/open-dossier-log/open-dossier-log-context";
import { SaveFormProvider } from "../shared/save-form/save-form-provider";
import UserDetailsContext from "../shared/user-details/user-details-context";
import AuthenticatedAdviesBoxSchermen from "./AuthenticatedAdviesBoxSchermen";
import { AppDataProvider } from "../navigation/appdata-context";
import NewDashboardComponent from "../dashboard/new/new-dashboard";
import { DashboardDataContext } from "../dashboard/new/new-dashboard-context";
import "./AuthenticatedAdviesBoxAppWithDrawer.scss";
import { NewDashboardContainerComponent } from "../dashboard/new/container/new-dashboard-container";
import { useFeature } from "../shared/feature/use-feature";

const AuthenticatedAdviesBoxAppWithDrawer = (props: {
  gebruikersVestigingIds?: string[];
  organisatieVestigingIds?: string[];
}): ReactElement => {
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { setLezenEnSchrijvenRechtenVestigingen, setLezenEnSchrijvenRechtenOrganisatie } = useContext(
    OpenDossierLogContext
  );
  const location = useLocation();
  const { open, setOpen } = useContext(DashboardDataContext);
  const contentRef = useRef<HTMLDivElement>(null);
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  useUpscope(user?.profile.name, [getNaamAdviseur(userDetails)]);

  useEffect(() => {
    if (props.gebruikersVestigingIds && userDetails.klantdossierRechtenVestigingen) {
      let vestigingenRechten = null;
      vestigingenRechten = userDetails.klantdossierRechtenVestigingen;
      setLezenEnSchrijvenRechtenVestigingen(vestigingenRechten);
    }
    if (userDetails.klantdossierRechtenOrganisatie) {
      let organisatieRechten = null;
      organisatieRechten = userDetails.klantdossierRechtenOrganisatie;
      setLezenEnSchrijvenRechtenOrganisatie(organisatieRechten);
    }
  }, [
    setLezenEnSchrijvenRechtenVestigingen,
    setLezenEnSchrijvenRechtenOrganisatie,
    userDetails.klantdossierRechtenVestigingen,
    userDetails.klantdossierRechtenOrganisatie,
    props.gebruikersVestigingIds
  ]);

  useEffect(() => {
    if (!contentRef.current) return;

    if (open) {
      contentRef.current.setAttribute("inert", "");
      return;
    }

    contentRef.current.removeAttribute("inert");
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location, setOpen]);

  return (
    <div data-testid="authenticated-advies-box-app-with-router">
      <SaveFormProvider>
        <AppDataProvider>
          <Drawer>
            <Title appName="Advies" />
            <ErrorMessageBox />
            <Switch>
              <Route
                path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/(\S*?)"
                render={() => <TopNavigation situatie={"voorstel"} />}
              ></Route>
              <Route
                path="/vestiging/:vestiging/adviesdossier/:adviesdossier/(\S*?)"
                render={() => <TopNavigation situatie={"huidig"} />}
              ></Route>
            </Switch>
            <div className="row no-gutters">
              <div className={"col drawer__body"}>
                <div ref={contentRef}>
                  <AuthenticatedAdviesBoxSchermen
                    gebruikersVestigingIds={props.gebruikersVestigingIds ? props.gebruikersVestigingIds : undefined}
                    organisatieVestigingIds={props.organisatieVestigingIds ? props.organisatieVestigingIds : undefined}
                  />
                </div>
                {featureNewDashboard && <NewDashboardContainerComponent />}
              </div>

              {featureNewDashboard && (
                <>
                  <Route
                    path="/vestiging/:vestiging/adviesdossier/:adviesdossier/huidige/(\S*?)"
                    render={() => (
                      <div className="col-3 col-xl-2">
                        <NewDashboardComponent situatie={"huidig"} />
                      </div>
                    )}
                  ></Route>
                  <Route
                    path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel/producten/(\S*?)"
                    render={() => (
                      <div className="col-3 col-xl-2">
                        <NewDashboardComponent situatie={"voorstel"} />
                      </div>
                    )}
                  ></Route>
                </>
              )}
            </div>
          </Drawer>
        </AppDataProvider>
      </SaveFormProvider>
    </div>
  );
};

export default AuthenticatedAdviesBoxAppWithDrawer;
