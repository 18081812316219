import { Card, CardWrapper, PageLoading, useAdviesboxDataRepository, useRequestInit, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Prompt, RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { customUserConfirmationMessages } from "../confirmation-enum";
import { PersonaliaWaarschuwingenOutput, ValidationResultModel } from "../.generated/forms/formstypes";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import Aanvrager1Adres from "./aanvrager1-adres/aanvrager1-adres";
import Aanvrager1BurgerlijkeStatus from "./aanvrager1-burgerlijke-status/aanvrager1-burgerlijke-status";
import Aanvrager1Extra from "./aanvrager1-extra/aanvrager1-extra";
import Aanvrager1Levensgeschiedenis from "./aanvrager1-levensgeschiedenis/aanvrager1-levensgeschiedenis";
import Aanvrager1 from "./aanvrager1/aanvrager1";
import Aanvrager2Adres from "./aanvrager2-adres/aanvrager2-adres";
import Aanvrager2BurgerlijkeStatus from "./aanvrager2-burgerlijke-status/aanvrager2-burgerlijke-status";
import Aanvrager2Extra from "./aanvrager2-extra/aanvrager2-extra";
import Aanvrager2Levensgeschiedenis from "./aanvrager2-levensgeschiedenis/aanvrager2-levensgeschiedenis";
import Aanvrager2 from "./aanvrager2/aanvrager2";
import { initPersonaliaSideEffects } from "./infra/determine-personalia-side-effects";
import { mapPersonaliaDlTargetToUiField } from "./infra/map-personalia-dl-target-to-ui-field";
import { mapPersonaliaUiToDl } from "./infra/map-personalia-ui-to-dl";
import { PersonaliaProps, personaliaSchema, PersonaliaState } from "./infra/personalia-schema";
import MedeAanvragerOpties from "./mede-aanvrager-opties/mede-aanvrager-opties";
import OpslagWarningModal from "./opslag-warning-modal/opslag-warning-modal";

const Personalia = (props: FormikProps<PersonaliaState> & PersonaliaProps & RouteComponentProps): ReactElement => {
  const clickedPath = useRef<string>("");
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [warnings, setWarnings] = useState([] as ValidationResultModel[][]);
  const { settings, params, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const [routeToClickedPath, setRouteToClickedPath] = useState(false);
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Personalia/Waarschuwingen`;
  const {
    tarieven,
    values,
    isValid,
    validateForm,
    setFieldValue,
    saveData,
    resetForm,
  } = props;

  /*istanbul ignore next*/
  const { fetchData, loading } = useAdviesboxDataRepository<PersonaliaWaarschuwingenOutput, ValidationResultModel[][]>(
    url,
    {
      method: "POST",
      preventAbort: true,
      getDataId: () => "",
      mapDlToUi: (_a, _b) => null,
      mapUiToDl: () => mapPersonaliaUiToDl(values),
      mapTargetToUiField: mapPersonaliaDlTargetToUiField,
      onSuccess: (_, res) => {
        const resWarnings = [
          res?.herberekeningOptie1Waarschuwingen || [],
          res?.herberekeningOptie2Waarschuwingen || []
        ];
        setWarnings(resWarnings);

        if (resWarnings.some(warning => warning.length)) {
          setShowModal(true);
          return;
        }

        if (!clickedPath.current) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          saveData(values);
          resetForm(props); // Belangrijk!! Props moeten meegegeven worden aan resetForm anders reset het formulier naar initial values
          return;
        }

        setRouteToClickedPath(true);
      }
    }
  );

  const aanvrager1AdresRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const aanvrager2AdresRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const aanvrager1BurgerlijkeStatusRef = [useRef(null), useRef(null), useRef(null)];
  const aanvrager2BurgerlijkeStatusRef = [useRef(null), useRef(null), useRef(null)];
  const aanvrager1ExtraRef = useRef(null);
  const aanvrager2ExtraRef = useRef(null);
  const aanvrager1LevensgeschiedenisRef = useRef(null);
  const aanvrager2LevensgeschiedenisRef = useRef(null);
  const hasMedeAanvrager = values.medeAanvragerOpties.medeAanvrager;

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    validateForm(values);
  }, [validateForm, values]);

  // testen hiervan gaat niet geheel aangezien deze allemaal afhankelijk zijn van de preSaveConfirmation
  /* istanbul ignore next */
  useEffect(() => {
    if (!routeToClickedPath) return;
    setRouteToClickedPath(false);
    if (!clickedPath.current) return;
    history.push(clickedPath.current);
  }, [routeToClickedPath, setRouteToClickedPath, clickedPath, history]);

  // Testen hiervan gaat niet geheel door de saveData die in een test gemocked wordt.
  /* istanbul ignore next */
  const preSaveConfirmation = (path?: string): void => {
    if (!user || !isValid) return;
    clickedPath.current = path ?? "";
    setFieldValue("preventSave", false);
    fetchData();
  };

  const screen = (
    <FormFirstFocus>
      <Form>
        <Prompt
          when={values.preventSave && isValid}
          message={
            /* istanbul ignore next */
            (): string => {
              return customUserConfirmationMessages.personalia;
            }
          }
        />
        {loading && <PageLoading />}
        <ISWSideEffects<PersonaliaState> sync={initPersonaliaSideEffects} />
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Personalia</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton
                  context={props}
                  triggerPreSaveConfirmation={values.preventSave && isValid}
                  preSaveConfirmation={preSaveConfirmation}
                />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <div className="d-flex flex-wrap flex-row">
          <CardWrapper className="pl-3 medeaanvrager-container" maxRowCount={4}>
            <Card>
              <MedeAanvragerOpties />
            </Card>
          </CardWrapper>
          <CardWrapper flexType="flex-column" className="pl-3">
            <Card title="Aanvrager 1">
              <Aanvrager1 />
            </Card>
            <Card>
              <Aanvrager1Extra sourceRef={aanvrager1ExtraRef} targetRef={aanvrager2ExtraRef} />
            </Card>
            <Card>
              <Aanvrager1Adres sourceRef={aanvrager1AdresRef} targetRef={aanvrager2AdresRef} />
            </Card>
            <Card>
              <Aanvrager1BurgerlijkeStatus
                tarieven={tarieven}
                sourceRef={aanvrager1BurgerlijkeStatusRef}
                targetRef={aanvrager2BurgerlijkeStatusRef}
              />
            </Card>
            <Card>
              <Aanvrager1Levensgeschiedenis
                sourceRef={aanvrager1LevensgeschiedenisRef}
                targetRef={aanvrager2LevensgeschiedenisRef}
              />
            </Card>
            {/* <Card>
            <Advies />
          </Card> */}
          </CardWrapper>
          {hasMedeAanvrager && (
            <CardWrapper flexType="flex-column" className="pr-3 p-left">
              <Card title="Aanvrager 2">
                <Aanvrager2 />
              </Card>
              <Card>
                <Aanvrager2Extra sourceRef={aanvrager2ExtraRef} targetRef={aanvrager1ExtraRef} />
              </Card>
              <Card>
                <Aanvrager2Adres sourceRef={aanvrager2AdresRef} targetRef={aanvrager1AdresRef} />
              </Card>
              <Card>
                <Aanvrager2BurgerlijkeStatus
                  tarieven={tarieven}
                  sourceRef={aanvrager2BurgerlijkeStatusRef}
                  targetRef={aanvrager1BurgerlijkeStatusRef}
                />
              </Card>
              <Card>
                <Aanvrager2Levensgeschiedenis
                  sourceRef={aanvrager2LevensgeschiedenisRef}
                  targetRef={aanvrager1LevensgeschiedenisRef}
                />
              </Card>
              {/* <Card>
                <Advies />
              </Card> */}
            </CardWrapper>
          )}
        </div>
        <Debug />
      </Form>
      <OpslagWarningModal
        resetForm={/* istanbul ignore next */ () => resetForm(props)} // Belangrijk!! Props moeten meegegeven worden aan resetForm anders reset het formulier naar initial values      
        clickedRoute={clickedPath.current}
        warnings={warnings}
        setRouteToClickedPath={setRouteToClickedPath}
        setWarnings={setWarnings}
        showModal={showModal}
        setShowModal={setShowModal}
       
        saveData={saveData}
      />
    </FormFirstFocus>
  );

  return screen;
};

Personalia.displayName = "Personalia";

export default withAdviesboxFormik<PersonaliaProps, PersonaliaState>({
  mapPropsToValues: (e: PersonaliaProps): PersonaliaState => ({
    ...e
  }),
  validationSchema: personaliaSchema,
  enableReinitialize: false
})(withRouter(Personalia));
