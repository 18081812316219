import {
  beschikbareOrvProducten,
  Card,
  CardWrapper,
  DataGrid,
  ErrorPage,
  ModalButton,
  PageLoading,
  PlatformFoutenSamenvatting,
  FormFirstFocus
} from "adviesbox-shared";
import { Form, FormikContextType, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";
import { OrvBasis } from "../.generated/producten/productentypes";
import { getSituatieFilter } from "../producten-overzicht/infra/product-situatie-filter";
import { AlleMogelijkeProductBasisTypes } from "../producten-overzicht/infra/producten-overzicht-types";
import ProductSelectieAjax from "../producten-overzicht/product-selectie/product-selectie-ajax";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { SaveButton } from "../shared/components/save-button/save-button";
import { useInstellingenBeheerPartijenData } from "../shared/hooks/use-instellingen-beheer-partijen-data";
import { partijFilter } from "../shared/utils/partij-filter";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { OrvProps, orvSchemaHuidig, orvSchemaVoorstel, OrvsState } from "./infra/orv-schema";
import { getOrvColumns } from "./orv-columns";
import OrvDetails from "./orv-details/orv-details";
import { createFormValidationContext, newOrvObjectFactory, onSaveOrvFunction } from "./orv-helpers";
import { OrvVergelijkenModal } from "./orv-vergelijken-modal/orv-vergelijken-modal";
import { Dashboard } from "../dashboard/dashboard";
import { useFeature } from "../shared/feature/use-feature";

const Orv = (props: FormikContextType<OrvsState> & OrvProps): ReactElement => {
  const formik = useFormikContext<OrvsState>();
  const { values, situatie, setFieldValue } = props;
  const selectedState = useState(0);
  const [selected, setSelected] = selectedState;
  const { producten, ingangsdatumVoorstel } = values;
  const selectedProduct = producten && producten[selected];
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  const {
    data: beschikbareVerzekeraarsInstellingenData,
    loading: verzekeraarsLoading,
    error: verzekeraarsError
  } = useInstellingenBeheerPartijenData("Verzekeraars");

  if (verzekeraarsError) {
    return <ErrorPage error={verzekeraarsError} data={beschikbareVerzekeraarsInstellingenData} />;
  }

  let kanOpslaan = true;
  producten.forEach(p => { if (p.incorrecteProductkenmerken) kanOpslaan = false; });

  let additionalButton;
  if (!kanOpslaan) {
    additionalButton = <></>;
  } else if (
    situatie === "huidig" &&
    selectedProduct &&
    selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null
  ) {
    additionalButton = (
      <ProductWijzigingenMeenemenButton
        name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
      />
    );
  } else if (situatie === "voorstel") {
    additionalButton = (
      <div>
        <ModalButton
          className={"btn btn-primary ml-2"}
          parent={"orvVergelijkenModal"}
          content={"ORV vergelijker"}
          size="lg"
        >
          <OrvVergelijkenModal
            data={{ ...values.orvVergelijkenModal, modalOpen: true }}
            onSave={
              /* istanbul ignore next */ (result): void => {
                onSaveOrvFunction(result, setFieldValue, values, situatie, setSelected);
              }
            }
          />
        </ModalButton>
      </div>
    );
  }

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {(props.isSubmitting || verzekeraarsLoading) && <PageLoading />}
            <CardWrapper className="px-3">
              <div className="text-container">
                <h2>Overlijdensrisicoverzekering</h2>
                {kanOpslaan && (<div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>)}
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />
            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
                <DataGrid
                  masterDetail
                  captionToLowerCase={false}
                  rowCaption="ORV"
                  columns={getOrvColumns(situatie)}
                  rowSelected={selectedState}
                  name="producten"
                  additionalButton={additionalButton}
                  showButtonAddRow={kanOpslaan}
                  popup={
                    <ProductSelectieAjax
                      situatie={situatie}
                      productSoort="orv"
                      productFilter={
                        /* istanbul ignore next */ (product: AlleMogelijkeProductBasisTypes) => {
                          return (
                            getSituatieFilter(
                              situatie,
                              product,
                              beschikbareVerzekeraarsInstellingenData.verzekeraars?.partijen
                            ) &&
                            partijFilter(
                              { maatschappijCode: product.maatschappijCode, code: product.code } as OrvBasis,
                              beschikbareOrvProducten
                            )
                          );
                        }
                      }
                      productFactory={
                        /* istanbul ignore next */ productSelectie =>
                          newOrvObjectFactory(
                            productSelectie,
                            ingangsdatumVoorstel,
                            situatie,
                            values.aflosProductNew,
                            values
                          )
                      }
                    />
                  }
                />
              </Card>
            </CardWrapper>
            {selectedProduct && <OrvDetails selected={selected} situatie={situatie} />}
            <DevDebug />
          </div>
          { !featureNewDashboard && (
            <Dashboard situatie={situatie} saveData={props.saveData} formikParent={formik} />
          )}
        </div>
      </Form>
    </FormFirstFocus>
  );
};
Orv.displayName = "Orv";
export default withAdviesboxFormik<OrvProps & WithSaveData<OrvsState>, OrvsState>({
  // Transform outer props into form values
  mapPropsToValues: (e: OrvProps): OrvsState => ({
    producten: e.producten,
    aanvrager1: e.aanvrager1,
    aanvrager2: e.aanvrager2,
    ingangsdatumVoorstel: e.ingangsdatumVoorstel,
    orvVergelijkenModal: e.orvVergelijkenModal,
    aflosProductNew: e.aflosProductNew
  }),
  validationSchema: (e: OrvProps): Yup.ObjectSchema<any> => {
    if (e.situatie === "huidig") {
      return orvSchemaHuidig;
    }
    return orvSchemaVoorstel;
  },
  createValidationContext: createFormValidationContext
})(Orv);
