/* istanbul ignore file */

import {
  AuthProvider,
  ErrorBoundaryContextProvider,
  SettingsProvider,
  SigninSilentOidc,
  ValidatiePopup,
  withErrorBoundary,
  RouteEvents
} from "adviesbox-shared";
import React, { ReactElement, useRef, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdviesboxApp from "./adviesbox-app/AdviesBoxApp";
import logo from "./assets/adviesbox-logo.svg";
import { customUserConfirmationMessages } from "./confirmation-enum";
import ProductenProvider from "./producten-overzicht/infra/producten-provider";
import OpenDossierLogProvider from "./shared/open-dossier-log/open-dossier-log-provider";
import UserDetailsProvider from "./shared/user-details/user-details-provider";
import "./styles/style.scss";

const App = (): ReactElement => {
  const [modalShow, setModalShow] = useState(false);
  const continueRef = useRef<any>(null);

  return (
    <div className="wrapper">
      <ValidatiePopup
        infotekst="browserInfo"
        show={modalShow}
        onHide={(): void => {
          setModalShow(false);
          continueRef.current(false);
        }}
        onSubmit={(): void => {
          setModalShow(false);
          if (continueRef.current) {
            continueRef.current(true);
          }
        }}
        navigatiepopup
      />

      <ErrorBoundaryContextProvider>
        <OpenDossierLogProvider>
          <BrowserRouter
            getUserConfirmation={(message: string, callback: (ok: boolean) => void): void => {
              if (customUserConfirmationMessages[message as customUserConfirmationMessages]) return;
              continueRef.current = callback;
              setModalShow(true);
            }}
          >
            <SettingsProvider loadingLogo={logo}>
              <Switch>
                <Route path="/silent-redirect" component={withErrorBoundary(SigninSilentOidc)} />
                <Route
                  render={(): ReactElement => (
                    <UserDetailsProvider>
                      <ProductenProvider>
                        <>
                          {/* //todo: please keep in place (disabled because of security finding: P105700-2-15): 
                          <GoogleAnalyticsTracker /> */}
                          <AuthProvider>
                            <RouteEvents>
                              <AdviesboxApp />
                            </RouteEvents>
                          </AuthProvider>
                        </>
                      </ProductenProvider>
                    </UserDetailsProvider>
                  )}
                />
              </Switch>
            </SettingsProvider>
          </BrowserRouter>
        </OpenDossierLogProvider>
      </ErrorBoundaryContextProvider>
    </div>
  );
};

App.displayName = "App";

export default withErrorBoundary(App);
