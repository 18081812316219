import { LocalDate } from "@js-joda/core";
import { CellInfo } from "react-table-6";
import * as Yup from "yup";
import { InferType } from "yup";
import { GeslachtOpties, ZoekResultaatSorteerOptions, Dossierstatus } from "../../.generated/forms/formstypes";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { birthdate2age } from "../../shared/utils/birthdate-to-age";
import { hasValue } from "../../shared/utils/helpers";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { localDateSchema, localDateTimeSchema, nullableLocalDateSchema } from "adviesbox-shared";

export enum SearchCategoriesAdvies {
  Naam = "Naam",
  Postcode = "Postcode",
  Woonplaats = "Woonplaats",
  Geboortedatum = "Geboortedatum"
}

export enum AdviesDossierStatus {
  Geen = "Geen",
  InBehandeling = "InBehandeling",
  Actief = "Actief",
  NietActief = "NietActief"
}
export const zoekenAdviesdossierSchema = Yup.object({
  klantdossierId: Yup.string(),
  adviesdossierId: Yup.string(),
  laatstBewerktDatum: Yup.string()
    .nullable()
    .default(null),
  laatstBewerktTijd: Yup.string()
    .nullable()
    .default(null),
  geboorteData: Yup.string()
    .nullable()
    .default(null),
  postcode: Yup.string()
    .nullable()
    .default(null),
  woonplaats: Yup.string()
    .nullable()
    .default(null),
  vestiging: Yup.string()
    .nullable()
    .default(null),
  vestigingId: Yup.string()
    .nullable()
    .default(null),
  aanleiding: Yup.string()
    .nullable()
    .default(null),
  omschrijving: Yup.string(),
  status: Yup.string()
    .nullable()
    .default(null),
  bemiddelingDossierStatus: yupNullableEnum(Dossierstatus).default(null)
});

const aanvragerSchema = Yup.object({
  klantId: Yup.string().default(""),
  voornaam: Yup.string().default(""),
  tussenVoegsel: Yup.string()
    .nullable()
    .default(null),
  geboorteDatum: localDateSchema
    .typeError("Vul een geldige geboortedatum in.")
    .test({
      message: "Vul een geldige datum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value.year() >= 1900
    })
    .test({
      message: "Vul een geldige geboortedatum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value < LocalDate.now()
    })
    .test({
      message: "De aanvrager moet minimaal 18 jaar oud zijn.",
      test: (value: LocalDate | null) => !hasValue(value) || birthdate2age(value) >= 18
    })
    .test({
      message: "Vul de geboortedatum in.",
      test: (value: LocalDate | null) => hasValue(value)
    }),
  achternaam: Yup.string()
    .default("")
    .min(2, "Veld bevat niet genoeg tekens.")
    .required("Vul de achternaam in."),
  geslacht: Yup.mixed<GeslachtOpties>()
    .oneOf(Object.values(GeslachtOpties))
    .nullable()
    .default(GeslachtOpties.Geen)
});

const partnerSchema = Yup.object({
  klantId: Yup.string().default(""),
  voornaam: Yup.string().default(""),
  tussenVoegsel: Yup.string()
    .nullable()
    .default(null),
  geboorteDatum: localDateSchema
    .typeError("Ongeldige datum.")
    .test({
      message: "Vul een geldige datum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value.year() >= 1900
    })
    .test({
      message: "Vul een geldige geboortedatum in.",
      test: (value: LocalDate | null) => !hasValue(value) || value < LocalDate.now()
    })
    .test({
      message: "De aanvrager moet minimaal 18 jaar oud zijn.",
      test: (value: LocalDate | null) => !hasValue(value) || birthdate2age(value) >= 18
    })
    .test({
      message: "Vul de geboortedatum in.",
      test: (value: LocalDate | null) => hasValue(value)
    }),
  achternaam: Yup.string()
    .default("")
    .required("Vul de achternaam in."),
  geslacht: Yup.mixed<GeslachtOpties>()
    .oneOf(Object.values(GeslachtOpties))
    .nullable()
    .default(GeslachtOpties.Geen)
});

export const zoekResultaatClientSchema = Yup.object({
  klantdossierId: Yup.string(),
  laatstGeopend: localDateTimeSchema.nullable().default(null),
  klant: Yup.string(),
  aantalDossiers: Yup.number(),
  adviseur: Yup.string(),
  adviseurId: Yup.string()
    .nullable()
    .default(null),
  vestiging: Yup.string(),
  geboorteData: Yup.array(nullableLocalDateSchema.nullable()).default([]),
  postcode: Yup.string()
    .nullable()
    .default(null),
  woonplaats: Yup.string()
    .nullable()
    .default(null),
  vestigingId: Yup.string()
    .nullable()
    .default(null),
  adviesDossiers: Yup.array(zoekenAdviesdossierSchema),
  naamAanvrager: Yup.string(),
  naamPartner: Yup.string()
    .nullable()
    .default(null)
});

export const zoekenSchema = Yup.object({
  vestiging: Yup.string().default("ALL"),
  optieDossiers: Yup.string().default("1"),
  sorteren: yupEnum(ZoekResultaatSorteerOptions).default(ZoekResultaatSorteerOptions.AchternaamOplopend),
  geboortedatum: nullableLocalDateSchema,
  woonplaats: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  searchValue: Yup.string().default(""),
  searching: Yup.boolean().default(false),
  zoekresultaten: Yup.array(zoekResultaatClientSchema).default([]),
  zoekresultatenMetToegangsrechten: Yup.array(zoekResultaatClientSchema).default([]),
  selectedClient: zoekResultaatClientSchema.nullable().default(null)
});

export const laatsteDossierSchema = Yup.object({
  adviesdossierId: Yup.string(),
  laatstGeopend: localDateTimeSchema,
  klant: Yup.string(),
  aanleiding: Yup.string(),
  omschrijving: Yup.string(),
  status: Yup.string()
});

export const laatsteDossiersSchema = Yup.object({
  aantalDossiers: Yup.number().default(0),
  laatsteDossiers: Yup.array(laatsteDossierSchema).default([]),
  inView: Yup.number().default(10)
});

export const nieuweKlantSchema = Yup.object({
  aanvrager1: aanvragerSchema,
  aanvrager2: Yup.object<InferType<typeof aanvragerSchema>>()
    .when("hasPartner", {
      is: true,
      then: partnerSchema
    })
    .default(partnerSchema.default()),
  hasPartner: Yup.boolean().default(false)
});

export const ExterneDossiersSchema = Yup.object({
  importTaakId: Yup.string(),
  importDatum: Yup.string().nullable(),
  koppelingNaam: Yup.string().nullable(),
  klantNaam: Yup.string().nullable(),
  koppelingKey: Yup.string().nullable(),
  status: Yup.string()
});

export const zoekschermSchema = Yup.object({
  zoeken: zoekenSchema,
  laatsteDossiers: laatsteDossiersSchema,
  externeDossiers: Yup.array(ExterneDossiersSchema).default([]),
  nieuweKlant: nieuweKlantSchema,
  recentGeopendError: Yup.boolean().default(false),
  totalNumberOfPages: Yup.number().default(0),
  totalNumberOfResults: Yup.number().default(0)
});

export type ZoekschermState = InferType<typeof zoekschermSchema>;
export type ZoekenType = InferType<typeof zoekenSchema>;
export type ZoekResultaatType = InferType<typeof zoekResultaatClientSchema>;
export type ZoekenAdviesDossierType = InferType<typeof zoekenAdviesdossierSchema>;
export type LaatsteDossierType = InferType<typeof laatsteDossierSchema>;
export type LaatsteDossiersType = InferType<typeof laatsteDossiersSchema>;
export type NieuweKlantType = InferType<typeof nieuweKlantSchema>;
export type ExterneDossiersType = InferType<typeof ExterneDossiersSchema>;

export enum TableColumnId {
  DeleteClientButton = "DeleteClientButton",
  DeleteDossierButton = "DeleteDossierButton",
  Aanleiding = "Aanleiding",
  Status = "Status",
  Vestiging = "Vestiging"
}

export type LogRequestBody = {
  username: string;
  datumTijdOpening: Date;
};

export type NieuwKlantRequestBody = {
  achternaamAanvrager1: string;
  tussenvoegselAanvrager1: string | null;
  roepnaamAanvrager1: string;
  geboortedatumAanvrager1: string;
  heeftAanvrager2: boolean;
  achternaamAanvrager2: string | null;
  tussenvoegselAanvrager2: string | null;
  roepnaamAanvrager2: string | null;
  geboortedatumAanvrager2: string | null;
};

export type DeleteClientButtonProps = {
  deleteClientFunc: (klantdossierId: string) => () => void;
  cellInfo: CellInfo;
};

export const vestigingSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  naam: Yup.string().default(""),
  isHoofdvestiging: Yup.boolean().default(false),
  waarschuwingsBericht: Yup.string()
    .default("")
    .nullable(),
  telefoonnummer: Yup.string().default(""),
  emailadres: Yup.string().default(""),
  bezoekadres: adresSchema,
  postadres: adresSchema.nullable().default(null),
  afwijkendPostadresTonen: Yup.boolean()
    .default(false)
    .nullable(),
  afmRegistratienummer: Yup.string().default(""),
  platformId: Yup.string()
    .nullable()
    .default(""),
  hasChanged: Yup.boolean().default(false),
  isDeleted: Yup.boolean().default(false),
  putChanges: Yup.boolean().default(false),
  postChanges: Yup.boolean().default(false),
  savedChanges: Yup.boolean().default(false),
  triggeredByModal: Yup.boolean().default(false)
});

export type VestigingType = Yup.InferType<typeof vestigingSchema>;

export const vestigingenSchema = Yup.object({
  vestigingen: Yup.array(vestigingSchema).default([])
});
export type VestigingenType = Yup.InferType<typeof vestigingenSchema>;
