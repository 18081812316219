import React, { FC } from "react";
import LastenOverzichtTable from "../lastenoverzicht-table/lastenoverzicht-table";
import { Card, CardWrapper } from "adviesbox-shared";
import {ProductOverzicht} from "../productoverzicht/product-overzicht";
import { Formik, FormikProps } from "formik";
import LastenOverzichtChart from "../lastenoverzicht-chart/lastenoverzicht-chart";
import "./snel-inzicht-drawer.scss";

type KeuzeOpties = {
  MaandlastenoverzichtRadioButtons: string
}

interface SnelInzichtDrawerProps {
  values?: KeuzeOpties
}

export const SnelInzichtDrawer: FC<SnelInzichtDrawerProps> = ({ values }) => {
  return (
    <Formik
      initialValues={values || {
        MaandlastenoverzichtRadioButtons: "tabel"
      }}
      onSubmit={/* istanbul ignore next */ () => {}}
    >
      {({ values }: FormikProps<any>) => (
        <div className="snel-inzicht">
          <CardWrapper maxRowCount={2} flexType={"flex-row"}>
            <Card title="Productoverzicht" className="col-12 col-md-3">
              <ProductOverzicht isVoorstel={true} />
            </Card>
            <>
              <Card
                title="Maandlastenoverzicht"
                radioOptions={[
                  { label: "Tabel", value: "tabel" },
                  { label: "Grafiek", value: "grafiek" }
                ]}
              >
                {values.MaandlastenoverzichtRadioButtons === "tabel" && <LastenOverzichtTable isVoorstel={true} />}
                {values.MaandlastenoverzichtRadioButtons === "grafiek" && <LastenOverzichtChart isVoorstel={true} />}
              </Card>
            </>
          </CardWrapper>
          {/* {values.MaandlastenoverzichtRadioButtons === "grafiek" && (
            <CardWrapper className="flex-row-reverse">
              <Card title="Jaar 2025" className="col-12 col-md-9">
                <LastenOverzichtCard />
              </Card>
            </CardWrapper>
          )} */}
        </div>
      )}
    </Formik>
  );
};