/* eslint-disable react/prop-types */
import React, { Suspense } from "react";
import { DataDisplay, Loading } from "../data-display/data-display";
import {
  DashboardOutput,
  DashboardTekort,
  KlantnaamReadOnly,
  DashboardScenarioWerkloosheid
} from "../../../.generated/forms/formstypes";
import { Tekort } from "../../components/tekort/tekort";
import { uitgangspuntenSchema, tekortSchema } from "../../infra/dashboard-schema";
import { klantnaamSchema } from "../../../shared/generic-parts/klantnaam/schema";

interface WerkloosheidResultaatProps {
  onClick: () => void;
  situatie:string
}

const getAanvrager = (data: DashboardOutput, partner = false): KlantnaamReadOnly => {
  const dash = data.dashboards?.[Object.keys(data.dashboards)[0]];
  return (partner ? dash?.aanvrager2 as any : dash?.aanvrager1 as any) ?? klantnaamSchema.default();
};

const getWerkloosheid = (data: DashboardOutput): DashboardScenarioWerkloosheid | null => {
  const dash = data.dashboards?.[Object.keys(data.dashboards)[0]];
  return dash?.werkloosheid ?? null;
};

const getTekort = (data: DashboardOutput): DashboardTekort => {
  return getWerkloosheid(data)?.tekort ?? tekortSchema.default();
};

export const WerkloosheidResultaat: React.FC<WerkloosheidResultaatProps> = ({ onClick, situatie }) => {
  return (
    <Suspense fallback={<Loading />}>
      <button
        type="button"
        className="new-dashboard__entry-btn"
        data-testid="button-testid"
        onClick={onClick}
      >
        <h2>WW</h2>
        <DataDisplay
          endpoint="Werkloosheid?berekenTekort=true"
          isVoorstel={situatie === "voorstel"}
          render={(data: DashboardOutput) => (
            <div>
              <Tekort
                tekort={getTekort(data)}
                uitgangspunten={getWerkloosheid(data)?.uitgangspunten ?? uitgangspuntenSchema.default()}
                aanvrager={getAanvrager(data) as any}
                partner={getAanvrager(data, true) as any}
              />
            </div>
          )}
        />
      </button>
    </Suspense>
  );
};