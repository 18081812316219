import React, { ReactElement, Suspense } from "react";
import { Loading, DataDisplay } from "../data-display/data-display";

export const SnelInzichtResultaat = ({ onClick, situatie }: { onClick: () => void, situatie: string }): ReactElement => {
  return (
    <Suspense fallback={<Loading />}>
      <button type="button" className="new-dashboard__entry-btn" data-testid="button-testid" onClick={onClick}>
        <h2>SNEL INZICHT</h2>
        <DataDisplay
          endpoint="quick_insight?bruto=1180&netto=1080"
          isVoorstel={situatie === "voorstel"}
          render={data => (
            <>
              <div>Bruto € {data.bruto} p.m.</div>
              <div>Netto € {data.netto} p.m.</div>
            </>
          )}
        />
      </button>
    </Suspense>
  );
};
