import React, { ReactElement, Suspense } from "react";
import { DataDisplay, Loading } from "../data-display/data-display";

export const HypotheekAcceptatieResultaat = ({ onClick, situatie }: { onClick: () => void, situatie: string }): ReactElement => {
  return (
    <Suspense fallback={<Loading />}>
      <button type="button" className="new-dashboard__entry-btn" data-testid="button-testid" onClick={onClick}>
        <h2>HYPOTHEEKACCEPTATIE</h2>
        <DataDisplay
          endpoint="mortgage_acceptance?lti=260226&ltv=220226"
          isVoorstel={situatie === "voorstel"}
          render={data => (
            <>
              <div>LTI € {data.lti} ✅</div>
              <div>LTV € {data.ltv} ✅</div>
              <div>Indictatie 🟥</div>
            </>
          )}
        />
      </button>
    </Suspense>
  );
};
