import { Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AcceptatieCard } from "./acceptatie-card/acceptatie-card";
import { HypotheekBijCard } from "./hypotheek-bij-card/hypotheek-bij-card";
import { AcceptatieProps, acceptatieSchema, AcceptatieState } from "./infra/acceptatie-schema";
import { withAdviesboxFormik } from "../../../../shared/utils/with-adviesbox-formik";
import { WithSaveData } from "../../../../shared/utils/save-data";
import { useFeature } from "../../../../shared/feature/use-feature";
import { AcceptatieChart } from "./acceptatie-chart/acceptatie-chart";

const AcceptatieComponent = (props: FormikProps<AcceptatieState> & AcceptatieProps): ReactElement => {
  const { values } = useFormikContext<AcceptatieState>();
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            <PlatformFoutenSamenvatting />
            {values.showPlaceholderCard && (
              <CardWrapper className="px-3">
                <Card>
                  <div className="col-12 text-center">
                    <h2 data-testid="placeholder-title">{values.placeholderTitle}</h2>
                    <p data-testid="placeholder-message">{values.placeholderMessage}</p>
                  </div>
                </Card>
              </CardWrapper>
            )}
            {!values.showPlaceholderCard && (
              <>
                <CardWrapper className="px-3" maxRowCount={2}>
                  <Card
                    title={
                      "Hypotheek bij " +
                      (values.hypotheekBijCard.geldverstrekkerNaam || "naam geldverstrekker onbekend") +
                      `${values.hypotheekBijCard.opNHGBasis ? " met NHG" : ""}`
                    }
                  >
                    <HypotheekBijCard />
                  </Card>
                  <Card title="Acceptatie">
                    <AcceptatieCard />
                  </Card>
                </CardWrapper>
                {featureNewDashboard && (
                  <CardWrapper className="px-3" maxRowCount={1}>
                    <AcceptatieChart />
                  </CardWrapper>
                )}
              </>
            )}
          </div>
        </div>
      </Form>
    </FormFirstFocus>
  );
};

export const Acceptatie = withAdviesboxFormik<AcceptatieProps & WithSaveData<AcceptatieState>, AcceptatieState>({
  // Transform outer props into form values
  mapPropsToValues: (e: AcceptatieProps): AcceptatieState => e,
  validationSchema: acceptatieSchema
})(AcceptatieComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Acceptatie.displayName = "Acceptatie";
