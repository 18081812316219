import React, { ReactElement } from "react";
import { Card, LineChart } from "adviesbox-shared";
import { PeriodeUitwerkingType, AcceptatieState } from "../infra/acceptatie-schema";
import { useFormikContext } from "formik";

interface MonthData {
  month: number;
  year: number;
  beschikbareRuimte: number | null;
  fictieveLast: number | null;
  toegestandeLast: number | null;
}

const generateDatasetFromPeriods = (periods: PeriodeUitwerkingType[]): MonthData[] => {
  const monthsMap = new Map<string, MonthData>();

  periods.forEach(period => {
    /* istanbul ignore next */
    if (!period.startDatum || !period.eindDatum) return;

    let currentDate = period.startDatum;
    const endDate = period.eindDatum;

    while (!currentDate.isAfter(endDate)) {
      const key = `${currentDate.year()}-${currentDate.monthValue()}`;
      const existingData = monthsMap.get(key);

      /* istanbul ignore next */
      if (!existingData) {
        monthsMap.set(key, {
          month: currentDate.month().value(),
          year: currentDate.year(),
          beschikbareRuimte: period.beschikbareRuimte,
          fictieveLast: period.werkelijkeMaandlast,
          toegestandeLast: period.toegestaneMaandlast
        });
      }

      currentDate = currentDate.plusMonths(1);
    }
  });

  return Array.from(monthsMap.values()).sort((a, b) => {
    return a.year - b.year || a.month - b.month;
  });
};

export const AcceptatieChart = (): ReactElement => {
  const { values } = useFormikContext<AcceptatieState>();
  const dataset = generateDatasetFromPeriods(values.specificatieIndicatieMeerMinder.uitwerkingen);

  return (
    <>
      <div className="w-100 h-50">
        <Card title="Ontwikkeling Toetslast">
          <LineChart
            datasets={[
              {
                label: "Fictieve last",
                data: dataset.map(/* istanbul ignore next */ c => c.fictieveLast),
                fill: true,
                fillColor: "rgba(20,192,20,0.1)",
                borderWidth: 3,
                radius: 0
              },
              {
                label: "Toegestande last",
                data: dataset.map(/* istanbul ignore next */ c => c.toegestandeLast),
                backgroundColor: "#C2F970",
                borderColor: "#C2F970",
                borderWidth: 3,
                radius: 0
              }
            ]}
            labels={dataset.map(/* istanbul ignore next */ c => `${c.year}`)}
            optionsObject={{
              responsive: true,
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Year-Month"
                  },
                  ticks: {
                    callback: /* istanbul ignore next */ function(val: any, index: any) {
                      return index % 12 === 0 ? dataset[index].year : "";
                    }
                  }
                },
                y: {
                  display: true,
                  min: 0
                }
              }
            }}
          />
        </Card>
      </div>
    </>
  );
};
