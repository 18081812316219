import {
  ErrorPage,
  getAppLocation,
  getCookie,
  getOTAP,
  getPathname,
  HttpErrorPage,
  NotificatiesProvider,
  PageLoading,
  SettingsContext,
  useRequestInit,
  withErrorBoundary
} from "adviesbox-shared";
import { RouteParams } from "adviesbox-shared/utils/types";
import React, { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import ComponentsOverview from "../ComponentsOverview";
import {
  Medewerker,
  MedewerkerOrganisatieOutput,
  RechtenOutPut,
  Vestiging,
  VestigingenOutput
} from "../.generated/licenties/licentiestypes";
import DossierAanvraagFoutenProvider from "../shared/dossier-aanvraag-fouten/dossier-aanvraag-fouten-provider";
import OpenDossierLogProvider from "../shared/open-dossier-log/open-dossier-log-provider";
import UserDetailsContext from "../shared/user-details/user-details-context";
import ZoekschermAjax from "../zoekscherm/zoekscherm-ajax";
import AuthenticatedAdviesBoxAppWithDrawer from "./AuthenticatedAdviesBoxAppWithDrawer";
import ExterneKoppelingImportAjax from "../import/externekoppeling-import-ajax";
import { DashboardDataProvider } from "../dashboard/new/new-dashboard-context";
import { FeatureProvider } from "../shared/feature/feature-context";

const AuthenticatedAdviesBoxApp = (): ReactElement => {
  const settingsContext = useContext(SettingsContext);
  const { setUserDetails } = useContext(UserDetailsContext);
  const { user, settings, requestInit } = useRequestInit();
  const [medewerkerUrl, setMedewerkerUrl] = useState<string | null>(null);
  const [medewerker, setMedewerker] = useState<Medewerker | null>(null);
  const [medewerkerId, setMedewerkerId] = useState<string | null>(null);
  const [organisatieId, setOrganisatieId] = useState<string | null>(null);
  const [rechtenUrl, setRechtenUrl] = useState<string | null>(null);
  const [rechten, setRechten] = useState<string[] | null>(null);
  const [medewerkerVestigingenUrl, setMedewerkerVestigingenUrl] = useState<string | null>(null);
  const [medewerkerVestigingen, setMedewerkerVestigingen] = useState<{ [key: string]: Vestiging } | null>(null);
  const [organisatieVestigingenUrl, setOrganisatieVestigingenUrl] = useState<string | null>(null);
  const [organisatieVestigingen, setOrganisatieVestigingen] = useState<{ [key: string]: Vestiging } | null>(null);
  const [vestigingId, setVestigingId] = useState<string>("");

  const linkToPortal = getAppLocation(window.location.origin, "ADV", "POR", settingsContext.baseUrls);
  const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);

  useMemo(() => {
    setMedewerkerUrl(user?.profile ? `${settings.licentiesOrigin}/Medewerkers/current` : null);
  }, [setMedewerkerUrl, user, settings]);

  const medewerkerRequest = useAbortableFetch<MedewerkerOrganisatieOutput>(medewerkerUrl, requestInit);

  useEffect(() => {
    if (
      !medewerkerRequest ||
      medewerkerRequest.loading ||
      medewerkerRequest.error ||
      !medewerkerRequest.data ||
      typeof medewerkerRequest.data === "string" ||
      !medewerkerRequest.data.isValid
    ) {
      setMedewerker(null);
      setMedewerkerId(null);
      setOrganisatieId(null);
      return;
    }

    setMedewerker(medewerkerRequest.data.medewerker);
    setMedewerkerId(medewerkerRequest.data.medewerkerId);
    setOrganisatieId(medewerkerRequest.data.organisatieId);
  }, [medewerkerRequest, setMedewerker, setMedewerkerId]);

  useMemo(() => {
    setRechtenUrl(medewerkerId ? `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Rechten` : null);
  }, [setRechtenUrl, settings, medewerkerId]);

  const rechtenRequest = useAbortableFetch<RechtenOutPut>(rechtenUrl, requestInit);

  useEffect(() => {
    if (
      !rechtenRequest ||
      rechtenRequest.loading ||
      rechtenRequest.error ||
      !rechtenRequest.data ||
      typeof rechtenRequest.data === "string" ||
      !rechtenRequest.data.isValid
    ) {
      setRechten(null);
      return;
    }

    setRechten(rechtenRequest.data.rechten);
  }, [setRechten, rechtenRequest]);

  useMemo(() => {
    setMedewerkerVestigingenUrl(
      medewerkerId ? `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Vestigingen` : null
    );
  }, [setMedewerkerVestigingenUrl, medewerkerId, settings]);

  const medewerkerVestigingenRequest = useAbortableFetch<VestigingenOutput>(medewerkerVestigingenUrl, requestInit);

  useEffect(() => {
    if (
      !medewerkerVestigingenRequest ||
      medewerkerVestigingenRequest.loading ||
      medewerkerVestigingenRequest.error ||
      !medewerkerVestigingenRequest.data ||
      typeof medewerkerVestigingenRequest.data === "string" ||
      !medewerkerVestigingenRequest.data.isValid
    ) {
      setMedewerkerVestigingen(null);
      return;
    }

    setMedewerkerVestigingen(medewerkerVestigingenRequest.data.vestigingen);
  }, [setMedewerkerVestigingen, medewerkerVestigingenRequest]);

  useEffect(() => {
    if (!medewerkerId || !organisatieId || !medewerker) {
      return;
    }

    const newUserDetails = {
      ...medewerker,
      medewerkerId: medewerkerId,
      organisatieId: organisatieId
    };

    setUserDetails(newUserDetails);
  }, [setUserDetails, medewerker, medewerkerId, organisatieId]);

  useMemo(() => {
    setOrganisatieVestigingenUrl(
      organisatieId ? `${settings.licentiesOrigin}/Organisatie/${organisatieId}/Vestigingen` : null
    );
  }, [setOrganisatieVestigingenUrl, settings, organisatieId]);

  const organisatieVestigingenRequest = useAbortableFetch<VestigingenOutput>(organisatieVestigingenUrl, requestInit);

  useEffect(() => {
    if (
      !organisatieVestigingenRequest ||
      organisatieVestigingenRequest.loading ||
      organisatieVestigingenRequest.error ||
      !organisatieVestigingenRequest.data ||
      typeof organisatieVestigingenRequest.data === "string" ||
      !organisatieVestigingenRequest.data.isValid
    ) {
      setOrganisatieVestigingen(null);
      return;
    }

    setOrganisatieVestigingen(organisatieVestigingenRequest.data.vestigingen);
  }, [setOrganisatieVestigingen, organisatieVestigingenRequest]);

  useEffect(() => {
    if (typeof vestigingIdCookie === "string") {
      setVestigingId(vestigingIdCookie);
    }
  }, [vestigingIdCookie, setVestigingId]);

  if (!user || !user.profile) {
    return <ErrorPage error={Error("Geen gebruiker of geen rechten toegekend voor deze gebruiker")} data={null} />;
  }

  if (
    !medewerkerRequest ||
    medewerkerRequest.loading ||
    !rechtenRequest ||
    rechtenRequest.loading ||
    !medewerkerVestigingenRequest ||
    medewerkerVestigingenRequest.loading ||
    !organisatieVestigingenRequest ||
    organisatieVestigingenRequest.loading
  ) {
    return <PageLoading />;
  }

  if (medewerkerRequest.error) {
    return <ErrorPage error={medewerkerRequest.error} data={medewerkerRequest.data} />;
  }

  if (rechtenRequest.error) {
    return <ErrorPage error={rechtenRequest.error} data={rechtenRequest.data} />;
  }

  if (medewerkerVestigingenRequest.error) {
    return <ErrorPage error={medewerkerVestigingenRequest.error} data={medewerkerVestigingenRequest.data} />;
  }

  if (organisatieVestigingenRequest.error) {
    return <ErrorPage error={organisatieVestigingenRequest.error} data={organisatieVestigingenRequest.data} />;
  }

  if (
    typeof medewerkerRequest.data === "string" ||
    !medewerkerRequest.data?.isValid ||
    typeof medewerkerVestigingenRequest.data === "string" ||
    !medewerkerVestigingenRequest.data?.isValid ||
    typeof rechtenRequest.data === "string" ||
    !rechtenRequest.data?.isValid ||
    typeof organisatieVestigingenRequest.data === "string" ||
    !organisatieVestigingenRequest.data?.isValid ||
    !medewerkerId
  ) {
    return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
  }

  if (rechten && !rechten.some(recht => recht === "ADV")) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  const organisatieVestigingenIds = organisatieVestigingen && Object.keys(organisatieVestigingen);
  const vestigingenIds = medewerkerVestigingen && Object.keys(medewerkerVestigingen);
  const vestigingId2 = vestigingenIds ? vestigingenIds[0] : "";

  return (
    <div data-testid="authenticated-app" className="adviesbox-advies">
      <NotificatiesProvider
        reloadTimeInSeconds={60}
        medewerkerId={medewerkerId}
        sourceApp="ADV"
        vestigingId={vestigingId}
      >
        <OpenDossierLogProvider>
          <DashboardDataProvider>
            <FeatureProvider vestigingId={vestigingId2}>
              <DossierAanvraagFoutenProvider>
                <Switch>
                  <Route path="/components" component={withErrorBoundary(ComponentsOverview)} />
                  <Route path="/users/:id" component={withErrorBoundary(ComponentsOverview)} />
                  <Route path="/vestiging/:vestiging/zoeken" component={withErrorBoundary(ZoekschermAjax)} />
                  <Route
                    path="/vestiging/:vestiging/import/:koppelingKey/taak/:importTaakId"
                    component={withErrorBoundary(ExterneKoppelingImportAjax)}
                  />
                  <Route
                    path="/vestiging/:vestiging/adviesdossier/:adviesdossier/voorstel/:voorstel"
                    render={
                      /* istanbul ignore next */ (): ReactElement => (
                        <AuthenticatedAdviesBoxAppWithDrawer
                          gebruikersVestigingIds={vestigingenIds || undefined}
                          organisatieVestigingIds={organisatieVestigingenIds || undefined}
                        />
                      )
                    }
                  />
                  <Route
                    path="/vestiging/:vestiging/adviesdossier/:adviesdossier"
                    render={
                      /* istanbul ignore next */ (): ReactElement => (
                        <AuthenticatedAdviesBoxAppWithDrawer
                          gebruikersVestigingIds={vestigingenIds || undefined}
                          organisatieVestigingIds={organisatieVestigingenIds || undefined}
                        />
                      )
                    }
                  />

                  <Route
                    exact
                    path={"/vestiging/:vestiging"}
                    render={
                      /* istanbul ignore next */ ({ history }: RouteComponentProps<RouteParams>) => {
                        const base = getPathname().charAt(getPathname().length - 1);
                        if (base === "/") {
                          history.push(`${getPathname()}zoeken`);
                          return <></>;
                        }
                        history.push(`${getPathname()}/zoeken`);
                        return <></>;
                      }
                    }
                  />

                  <Route
                    exact
                    path={"/"}
                    render={
                      /* istanbul ignore next */ ({ history }: RouteComponentProps) => {
                        if (!vestigingIdCookie) {
                          window.location.assign(linkToPortal);
                          return <></>;
                        }

                        history.push(`/vestiging/${vestigingIdCookie}/zoeken`);
                        return <></>;
                      }
                    }
                  />

                  <Route>
                    <HttpErrorPage status={404} returnUrl={linkToPortal} />
                  </Route>
                </Switch>
              </DossierAanvraagFoutenProvider>
            </FeatureProvider>
          </DashboardDataProvider>
        </OpenDossierLogProvider>
      </NotificatiesProvider>
    </div>
  );
};

AuthenticatedAdviesBoxApp.displayName = "AuthenticatedAdviesBoxApp";
export default AuthenticatedAdviesBoxApp;
