import { createSpanWithId, DataGrid, Icon, LabeledResult } from "adviesbox-shared";
import { FormikContextType, useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { CellInfo } from "react-table-6";
import { DashboardState } from "../../infra/dashboard-types";
import { getAoTextResources } from "../ao-resources";
import { scenarioDoorrekeningSchema, scenariosSchema } from "../infra/scenario-arbeidsongeschiktheid-modal-schema";

export const DeleteButtonScenarioDoorrekening = (formik: FormikContextType<DashboardState>, namePrefix: string) => (
  c: CellInfo
): ReactElement => {
  const { setFieldValue } = formik;
  const onClick = (): void => {
    const curr = formik.values.arbeidsongeschiktheid.scenarioDoorrekening.scenarios;

    setFieldValue(
      `${namePrefix}.scenarioDoorrekening.scenarios`,
      curr?.filter((_, i) => i !== c.index)
    );
    setFieldValue("dirty", true);
  };

  return c.index === 0 ? (
    <></>
  ) : (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <Button
        data-testid={`btn-verwijder-${c.index}`}
        id={`btn-verwijder-${c.index}`}
        variant="outline-secondary"
        onMouseUp={onClick}
        aria-label="delete knop"
      >
        <Icon name="prullenbak" alt="Verwijderen" />
      </Button>
    </div>
  );
};

type NamePrefix = "werkloosheid" | "overlijden" | "arbeidsongeschiktheid";

type ScenarioDoorrekeningProps = { namePrefix: NamePrefix };

export const ScenarioDoorrekening = ({ namePrefix }: ScenarioDoorrekeningProps): ReactElement => {
  const formik = useFormikContext<DashboardState>();
  return (
    <>
      <LabeledResult
        name={`${namePrefix}.scenarioDoorrekening.toetsjaarTekortToggleIsEnabled`}
        caption="Toetsjaar tekort"
        alignRight={true}
        result={(): string => {
          return formik.values.arbeidsongeschiktheid.scenarioDoorrekening.toetsjaarTekort.toString();
        }}
        tooltip={getAoTextResources("TooltipToetsjaarTekort")}
      />
      <DataGrid
        columns={[
          {
            Header: "Scenario",
            id: "soort",
            width: 85,

            Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original.soort)
          },
          {
            Header: "AO percentage",
            id: "aoPercentage",
            width: 85,

            Cell: "PercentageInput"
          },
          {
            Header: "Duurzaam AO",
            id: "duurzaamAo",
            width: 85,

            Cell: "CheckBox"
          },
          {
            Header: "Benutting verdiencapaciteit",
            id: "benuttingRvc",
            width: 120,

            Cell: "PercentageInput"
          },
          {
            Cell: DeleteButtonScenarioDoorrekening(formik, namePrefix),
            maxWidth: 20
          }
        ]}
        name={`${namePrefix}.scenarioDoorrekening.scenarios`}
        showButtonAddRow={formik.values.arbeidsongeschiktheid.scenarioDoorrekening.scenarios.length <= 2}
        validationSchema={scenarioDoorrekeningSchema}
        getNewRowValues={() => {
          return { ...scenariosSchema.default(), soort: "Extra", benuttingRvc: 0 };
        }}
        rowCaption="Scenario"
        editable={true}
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  ScenarioDoorrekening.displayName = "ScenarioDoorrekening";
