import { Card, CardWrapper, PageLoading, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useState } from "react";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { HypotheekOversluitenDataGrid } from "./data-grid/hypotheek-oversluiten-data-grid";
import { determineHypotheekOversluitenSideEffects } from "./infra/determine-hypotheek-oversluiten-side-effects";
import {
  HypotheekOversluitenProps,
  hypotheekOversluitenSchema,
  HypotheekOversluitenState
} from "./infra/hypotheek-oversluiten-schema";
import Toetsrente from "./toetsrente/toetsrente";
import Uitgangspunten from "./uitgangspunten/uitgangspunten";

const HypotheekOversluiten = (
  props: FormikProps<HypotheekOversluitenState> & WithSaveData<HypotheekOversluitenState>
): ReactElement => {
  const { isSubmitting } = props;
  const [berekendState, setBerekendState] = useState(false);

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {isSubmitting && <PageLoading />}
            <ISWSideEffects<HypotheekOversluitenState> sync={determineHypotheekOversluitenSideEffects} />
            <CardWrapper className="px-3">
              <div className="text-container">
                <h2>Hypotheek oversluiten</h2>
                <div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />

            <CardWrapper className="px-3" maxRowCount={7}>
              <Card title="Uitgangspunten">
                <Uitgangspunten />
              </Card>
              <Card title="Rente">
                <Toetsrente />
              </Card>
            </CardWrapper>

            <CardWrapper className="px-3">
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50" fullscreenTonen disableFullScreenButton={!berekendState}>
                <HypotheekOversluitenDataGrid berekend={berekendState} setBerekend={setBerekendState} />
              </Card>
            </CardWrapper>
            <DevDebug />
          </div>
        </div>
      </Form>
    </FormFirstFocus>
  );
};

HypotheekOversluiten.displayName = "HypotheekOversluiten";

export default withAdviesboxFormik<
  HypotheekOversluitenProps & WithSaveData<HypotheekOversluitenState>,
  HypotheekOversluitenState
>({
  mapPropsToValues: (e: HypotheekOversluitenProps): HypotheekOversluitenState => e,
  validationSchema: hypotheekOversluitenSchema
})(HypotheekOversluiten);
